import { Button, Popover, Position } from '@blueprintjs/core';
import { useState } from 'react';
import DatePickerWithConfirm from '../date-and-comparison-date-selector/date-picker-with-confirm';
import moment from 'moment';
import { getDayEnd, getDayStart } from '../../utils/dates';

const getButtonLabel = (selectedPeriod) =>
  `${moment(selectedPeriod.start).format('ddd MMM, Do')} - ${moment(
    selectedPeriod.end,
  ).format('ddd MMM, Do')}`;

type Props = {
  selectedPeriod: {
    start: String,
    end: String,
  },
  updateSelected: (selected: { start: String, end: String }) => void,
  maxDate?: Date,
  minDate?: Date,
};

const SimpleDatePicker = ({ selectedPeriod, updateSelected, maxDate, minDate }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleUpdate = (period) => {
    setIsOpen(false);
    updateSelected({
      start: moment(getDayStart(period.selectedDates.start).toISOString()),
      end: moment(getDayEnd(period.selectedDates.end).toISOString()),
    });
  };
  return (
    <Popover
      isOpen={isOpen}
      content={
        <DatePickerWithConfirm
          handlePresetClick={handleUpdate}
          handleOpenDatePickerWithConfirm={() => setIsOpen(false)}
          currentPeriod={{
            selectedDates: {
              start: moment(selectedPeriod.start).format('YYYY-MM-DD'),
              end: moment(selectedPeriod.end).format('YYYY-MM-DD'),
            },
            selectedPreset: 'custom',
          }}
          isComparisonPeriod={false}
          maxDate={maxDate}
          minDate={minDate}
        />
      }
      position={Position.BOTTOM}
    >
      <Button
        text={getButtonLabel(selectedPeriod)}
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
};
export default SimpleDatePicker;
