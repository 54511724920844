import moment from 'moment';
import {
  MAX_DAYS,
  MAX_ENTITIES,
  MAX_LOCATIONS,
  MAX_RECORDINGS,
  REDUCER_ACTIONS,
} from '../constants/heatmaps';
import { sortBy, uniq, uniqBy } from 'lodash';

export const validBreakdown = (heatmap, breakdown, values) => {
  try {
    switch (breakdown) {
      case '':
        return !heatmap.age && !heatmap.role && !heatmap.gender;
      case 'ages':
        return (
          heatmap.age && heatmap.age.length > 0 && values.includes(heatmap.age)
        );
      case 'genders':
        return (
          heatmap.gender &&
          heatmap.gender.length > 0 &&
          values.includes(heatmap.gender)
        );
      case 'roles':
        return heatmap.role && values.includes(heatmap.role);
      default:
        break;
    }
  } catch (error) {
    return false;
  }
};

export const findRecordings = (locationIdArray, recordings) => {
  try {
    return recordings
      .filter((recording) => locationIdArray.includes(recording.location.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    return [];
  }
};

export const getFlattenedTaxonomies = (taxonomies) => {
  try {
    return !!taxonomies && taxonomies.length > 0
      ? taxonomies.map((t) => t.values.map((v) => t.key + ':' + v)).flat()
      : [];
  } catch (error) {
    return [];
  }
};

export const isRequestTooLarge = (options) => {
  try {
    return (
      options?.locations?.length > MAX_LOCATIONS ||
      options?.recordings?.length > MAX_RECORDINGS ||
      options?.locations?.length + options?.recordings?.length > MAX_ENTITIES ||
      moment(options.start).diff(moment(options.end), 'days') > MAX_DAYS
    );
  } catch (error) {
    return true;
  }
};

export const generateHeatmapGridObject = (
  breakdowns,
  heatmaps,
  recordings,
  ctx,
  selectedRecordings,
) => {
  if (typeof heatmaps === 'undefined' || heatmaps.length === 0) {
    return [];
  }
  const filterHeatmaps = (
    items,
    recording_id,
    breakdownKey,
    breakdownValue,
  ) => {
    return items
      .filter((h) => {
        switch (breakdownKey) {
          case '':
            // all visitors
            return (
              h.recording_id === recording_id &&
              h.age === null &&
              h.role === null &&
              h.gender === null
            );
            break;
          case 'ages':
            // null values for role and gender
            return (
              h.recording_id === recording_id &&
              h.age === breakdownValue.replace('-', '_') &&
              h.role === null &&
              h.gender === null
            );
            break;
          case 'roles':
            // null values for age and gender
            return (
              h.recording_id === recording_id &&
              h.age === null &&
              h.role === breakdownValue &&
              h.gender === null
            );
            break;
          case 'genders':
            // null values for age and role
            return (
              h.recording_id === recording_id &&
              h.age === null &&
              h.role === null &&
              h.gender === breakdownValue
            );
            break;

          default:
            break;
        }
      })
      .map((h) => ({ local_time: h.local_time, url: h.url }));
  };

  const objects = [];

  for (const recording of selectedRecordings) {
    const r = recordings.find((r) => r.id === recording);
    const baseStructure = {
      recording_id: recording,
      location_id: r.location.id,
      location_name: r.location.name,
      recording_name: r.name,
      taxonomies: uniq(
        ctx.filter((c) => c.recording_id === recording).map((c) => c.taxonomy),
      ),
    };
    if (breakdowns.length === 0 || breakdowns[0].key !== '') {
      const key = breakdowns[0].key;
      const values = breakdowns[0].values;
      for (const value of values) {
        const structure = {
          ...baseStructure,
          key: baseStructure.recording_id + '_' + value,
          [key]: value,
          heatmaps: filterHeatmaps(heatmaps, recording, key, value),
        };
        objects.push(structure);
      }
    } else {
      // all visitors
      const structure = {
        ...baseStructure,
        key: baseStructure.recording_id + '_' + 'all visitors',
        heatmaps: filterHeatmaps(heatmaps, recording, '', undefined),
      };
      objects.push(structure);
    }
  }
  const sortOrder = ['location_name', 'recording_name', breakdowns[0]?.key];
  return sortBy(objects, sortOrder);
};

export const heatmapsReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_ACTIONS.SET_FILTER_OPTIONS:
      // manual filterOptions change
      // do not enable load heatmaps button on selected date change
      if (state.heatmaps && action.type !== REDUCER_ACTIONS.SET_SELECTED_DATE) {
        state.shouldFetchHeatmaps = true;
      }
      return { ...state, filterOptions: action.payload };
    case REDUCER_ACTIONS.SET_CARD_SIZE:
      return { ...state, cardSize: action.payload };
    case REDUCER_ACTIONS.SET_PREV_FETCHED_FILTER_OPTIONS:
      return { ...state, prevFetchedFilterOptions: { ...action.payload } };
    case REDUCER_ACTIONS.SET_SHOULD_FETCH_HEATMAPS:
      return { ...state, shouldFetchHeatmaps: action.payload };
    case REDUCER_ACTIONS.SET_HEATMAPS:
      return { ...state, heatmaps: action.payload };
    case REDUCER_ACTIONS.SET_FILTERED_RECORDINGS:
      return { ...state, filteredRecordings: action.payload };
    case REDUCER_ACTIONS.SET_ENABLED_LOCATIONS:
      return { ...state, enabledLocations: action.payload };
    case REDUCER_ACTIONS.SET_TAXONOMY_LIST:
      return { ...state, taxonomyList: action.payload };
    case REDUCER_ACTIONS.SET_CTX:
      return { ...state, ctx: action.payload };
    case REDUCER_ACTIONS.SET_TAXONOMY_KEY_STRINGS:
      return { ...state, taxonomyKeyStrings: action.payload };
    case REDUCER_ACTIONS.SET_IS_QUERY_SUBSET:
      return { ...state, isQuerySubset: action.payload };
    case REDUCER_ACTIONS.SET_SELECTED_DATE:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedDate: action.payload,
        },
      };
    default:
      return state;
  }
};

export const parseStringsToStructure = (inputArray) => {
  let result = [];
  if (!Array.isArray(inputArray)) return result;

  inputArray.forEach((str) => {
    const parts = str.split(':'); // Split by colon
    let currentLevel = result;

    parts.forEach((part, index) => {
      // If it's the last element in the chain
      if (index === parts.length - 1) {
        currentLevel.push(part);
        return;
      }

      // Check if an object with the current key already exists
      let existingEntry = currentLevel.find((item) => item.key === part);

      if (!existingEntry) {
        existingEntry = { key: part, values: [] };
        currentLevel.push(existingEntry);
      }

      currentLevel = existingEntry.values;
    });
  });

  return result;
};

export const generateTaxonomyListFromContexts = (contexts) => {
  if (!contexts) return [];
  const ctx = uniqBy(contexts, 'taxonomy').map((c) => c.taxonomy);

  return parseStringsToStructure(
    ctx.sort((a, b) => {
      if (a.startsWith('Store:') && !b.startsWith('Store:')) return -1;
      if (!a.startsWith('Store:') && b.startsWith('Store:')) return 1;
      return a.localeCompare(b, 'en', { numeric: true });
    })
  )
};

export const isQuerySubset = (type, previous, newValue, filterOptions) => {
  // this function checks if the current query is a subset of the previous query
  // initially intended to reduce the number of api calls when heatmaps are already loaded
  // currently unused but could be useful in the future

  switch (type) {
    case 'locations':
      return !!previous && !!previous.locations
        ? !previous.locations.includes(...newValue)
        : true;

    case 'recordings':
      if (
        !!filterOptions &&
        filterOptions?.taxonomies &&
        filterOptions?.taxonomies?.length === 0
      ) {
        return !!previous && !!previous.recordings
          ? !previous.recordings.includes(...newValue)
          : true;
      }
      return false;

    case 'taxonomies':
      if (!previous?.taxonomies?.length) return true;

      return !newValue.every((newTax) => previous.taxonomies.includes(newTax));

    case 'type':
      return !!previous && !!previous.type
        ? previous.type.key !== newValue.key
        : true;

    case 'selectedPeriod':
      return !!previous && !!previous.start && !!previous.end
        ? !(
            moment(newValue.start).isBetween(previous.start, previous.end) &&
            moment(newValue.end).isBetween(previous.start, previous.end)
          )
        : true;

    case 'reset':
      return true;

    default:
      return false;
  }
};