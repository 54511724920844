// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import HeatmapsPage from '../components/pages/heatmaps/index';
import {
  ORG_HAS_STAFF_DETECTION,
  DISABLE_AGE_GENDER,
} from '../constants/features';

function mapStateToProps(state, ownProps) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
    showStaffFilters: Selectors.userHasFeature(ORG_HAS_STAFF_DETECTION, state),
    disableAgeAndGenderOnFrontEnd: Selectors.userHasFeature(
      DISABLE_AGE_GENDER,
      state,
    ),
  };
}

async function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatmapsPage);
