import { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ButtonGroup,
  Icon,
  MenuDivider,
} from '@blueprintjs/core';
import {
  IndentedMenuItem,
  NoItems,
  StyledInputGroup,
  StyledMenu,
} from './styled';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';

export const AuraMenu = ({
  allItems,
  selectedItems,
  enabledItems,
  updateSelectedItems,
  noItemsText,
  itemsTypeText,
  canSelectAll,
  maxSelectedItems,
  groupedBy,
}) => {
  // Memoize the groupAndSortItems function
  const groupAndSortItems = useCallback((items) => {
    if (groupedBy === 'location.id') {
      // Group items by location.id
      const grouped = items.reduce((acc, item) => {
        const locationId = item.location?.id;
        const locationName = item.location?.name;
        if (!locationId) return acc;

        if (!acc[locationId]) {
          acc[locationId] = {
            locationId,
            locationName,
            items: [],
          };
        }
        acc[locationId].items.push(item);
        return acc;
      }, {});

      // Sort locations by name and sort items within each location
      return Object.values(grouped)
        .sort((a, b) => a.locationName.localeCompare(b.locationName))
        .map((group) => ({
          ...group,
          items: group.items.sort((a, b) => a.name.localeCompare(b.name)),
        }));
    }

    // If not grouped, just sort the items
    return items.sort(
      (a, b) =>
        typeof a.name !== 'undefined' &&
        typeof b.name !== 'undefined' &&
        a.name.localeCompare(b.name),
    );
  }, [groupedBy]);

  // Memoize initial sorted items
  const initialSortedItems = useMemo(() => groupAndSortItems(allItems), [allItems, groupAndSortItems]);

  const [visibleItems, setVisibleItems] = useState(initialSortedItems);
  const [filterSelection, setFilterSelection] = useState(selectedItems);

  // Update visible items when allItems changes
  useEffect(() => {
    setVisibleItems(groupAndSortItems(allItems));
  }, [allItems, groupAndSortItems]);

  // Update filter selection when selectedItems changes
  useEffect(() => {
    setFilterSelection(selectedItems);
  }, [selectedItems]);

  const handleFilterItemsChange = useCallback((id) => {
    if (!!maxSelectedItems && maxSelectedItems === 1) {
      setFilterSelection([id]);
    } else {
      setFilterSelection((prevSelection) => {
        if (prevSelection.includes(id)) {
          return prevSelection.filter((itemId) => itemId !== id);
        }
        return [...prevSelection, id];
      });
    }
  }, [maxSelectedItems]);

  const handleSelectAll = useCallback(() => {
    if (groupedBy === 'location.id') {
      const allIds = visibleItems.flatMap((group) =>
        group.items.map((item) => item.id),
      );
      setFilterSelection(allIds);
    } else {
      setFilterSelection(visibleItems.map((item) => item.id));
    }
  }, [groupedBy, visibleItems]);

  const handleClearSelection = useCallback(() => {
    setFilterSelection([]);
  }, []);

  const handleApplyFilter = useCallback(() => {
    updateSelectedItems(filterSelection);
  }, [updateSelectedItems, filterSelection]);

  const filterItemsByName = useCallback((e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (groupedBy === 'location.id') {
      // Filter while maintaining group structure
      const filteredGroups = groupAndSortItems(allItems)
        .map((group) => ({
          ...group,
          items: group.items.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerm) ||
              group.locationName.toLowerCase().includes(searchTerm),
          ),
        }))
        .filter((group) => group.items.length > 0);
      setVisibleItems(filteredGroups);
    } else {
      // Regular filtering
      setVisibleItems(
        groupAndSortItems(
          allItems.filter((item) =>
            item?.name.toLowerCase().includes(searchTerm),
          ),
        ),
      );
    }
  }, [allItems, groupedBy, groupAndSortItems]);

  const renderGroupedItems = useCallback((group) => (
    <Fragment key={group.locationId}>
      {/* Location header - non-selectable */}
      <MenuItem
        text={firstLetterUppercase(group.locationName)}
        style={{ pointerEvents: 'none', cursor: 'none' }}
      />
      {/* Items under this location */}
      {group.items.map((item) => (
        <IndentedMenuItem
          indentationLevel={1}
          key={item.id}
          text={firstLetterUppercase(item.name)}
          shouldDismissPopover={false}
          label={
            filterSelection.includes(item.id) ? <Icon icon="tick" /> : null
          }
          onClick={() => handleFilterItemsChange(item.id)}
          disabled={
            enabledItems &&
            enabledItems.length > 0 &&
            !enabledItems.includes(item.id)
          }
        />
      ))}
      <MenuDivider />
    </Fragment>
  ), [filterSelection, enabledItems, handleFilterItemsChange]);

  const renderItems = useMemo(() => {
    if (groupedBy === 'location.id') {
      return visibleItems.map(renderGroupedItems);
    }

    // Regular non-grouped rendering
    const checked = visibleItems.filter((item) =>
      filterSelection.includes(item.id),
    );
    const unchecked = visibleItems.filter(
      (item) => !filterSelection.includes(item.id),
    );

    return (
      <>
        {checked.length > 0 && (
          <Fragment>
            {checked.map((item) => (
              <MenuItem
                key={item.id}
                text={firstLetterUppercase(item.name)}
                shouldDismissPopover={false}
                label={<Icon icon="tick" />}
                onClick={() => handleFilterItemsChange(item.id)}
                disabled={false}
              />
            ))}
            <MenuDivider />
          </Fragment>
        )}
        {unchecked.length > 0 && (
          <Fragment>
            {unchecked.map((item) => (
              <MenuItem
                key={item.id}
                text={firstLetterUppercase(item.name)}
                shouldDismissPopover={false}
                onClick={() => handleFilterItemsChange(item.id)}
                disabled={
                  enabledItems &&
                  enabledItems.length > 0 &&
                  !enabledItems.includes(item.id)
                }
              />
            ))}
            <MenuDivider />
          </Fragment>
        )}
      </>
    );
  }, [visibleItems, filterSelection, enabledItems, groupedBy, handleFilterItemsChange, renderGroupedItems]);

  const isSelectAllDisabled = useMemo(() => 
    (groupedBy === 'location.id' &&
      filterSelection.length === visibleItems.flatMap((g) => g.items).length) ||
    (!groupedBy && filterSelection.length === visibleItems.length), 
  [groupedBy, filterSelection.length, visibleItems]);

  const hasVisibleItems = useMemo(() => 
    (groupedBy !== 'location.id' && visibleItems.length > 0) ||
    (groupedBy === 'location.id' && visibleItems.some((g) => g.items.length > 0)),
  [groupedBy, visibleItems]);

  return (
    <Menu>
      <StyledInputGroup
        leftIcon="search"
        placeholder={`Search ${itemsTypeText}`}
        onChange={filterItemsByName}
        autoComplete="off"
      />
      <MenuDivider />
      <StyledMenu>
        {renderItems}
        {(!visibleItems.length ||
          (groupedBy === 'location.id' &&
            !visibleItems.some((g) => g.items.length))) && (
          <NoItems>
            <Icon icon="warning-sign" />
            {noItemsText}
          </NoItems>
        )}
      </StyledMenu>
      {hasVisibleItems ? (
        <Fragment>
          <ButtonGroup fill={true}>
            {canSelectAll && (
              <Button
                icon="filter-list"
                disabled={isSelectAllDisabled}
                onClick={handleSelectAll}
              >
                Select all
              </Button>
            )}
            <Button
              icon="cross"
              disabled={filterSelection.length === 0}
              onClick={handleClearSelection}
            >
              Clear selection
            </Button>
          </ButtonGroup>
          <Button
            intent="success"
            style={{ marginTop: '0.25rem' }}
            fill={true}
            disabled={filterSelection.length === 0}
            onClick={handleApplyFilter}
          >
            Filter {firstLetterUppercase(itemsTypeText)}
          </Button>
        </Fragment>
      ) : null}
    </Menu>
  );
};

export default AuraMenu;
