import {
  GENDERS,
  ROLES,
  SORTED_AGE_KEYS,
} from '../constants/demographic-types';

// Eventually get showStaffFilters and disableAgeGenderOnFrontEnd from a context rather than redux
const useHeatmapBreakdownOptions = (
  showStaffFilters,
  disableAgeAndGenderOnFrontEnd,
  filterOptionsType
) => {
  if (filterOptionsType.key === 'interaction') {
    return [
      // { key: '', values: ['all visitors'] },
      {
        key: 'roles', values: [
          'customer-staff',
          'customer-customer',
          'staff-staff'
        ]
      }]
  }

  let breakdownItems = [{ key: '', values: ['all visitors'] }];
  if (!disableAgeAndGenderOnFrontEnd) {
    breakdownItems.push({
      key: 'ages',
      values: SORTED_AGE_KEYS.map((k) => k.replaceAll('_', '-')),
    });
    breakdownItems.push({ key: 'genders', values: GENDERS });
  }
  if (showStaffFilters) {
    breakdownItems.push({ key: 'roles', values: ROLES });
  }

  return breakdownItems;
};

export default useHeatmapBreakdownOptions;
