import Layout from '../../../../layouts/default-logged-in';
import SettingsPageHeader from '../../settings-page-header';
import { TablesBody, TablesWrapper, Wrapper } from '../../settings-page-styles';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useEffect, useState, useContext, useCallback } from 'react';
import { H1, Spinner } from '@blueprintjs/core';
import { fetchRecordingUptimes } from '../../../../../actions/uptime';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  Tooltip,
  YAxis,
  XAxis,
} from 'recharts';
import { firstLetterUppercase } from '../../../../../utils/firstLetterUppercase';
import { SETTINGS_MANAGE_SINGLE_RECORDING } from '../../../../../constants/routes';
import { getFonts } from '../../../../../styles/variables';
import {
  getDayEnd,
  getDayStart,
  getLabelForDate,
  getLabelForTime,
} from '../../../../../utils/dates';
import { fetchThumbnailsForRecordings } from '../../../../../actions/thumbnails';
import moment from 'moment';
import SimpleDatePicker from '../../../../date-and-comparison-date-selector/simple-date-picker';

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const WrapperHandler = ({ marginBottom, children }) => (
  <TablesWrapper style={marginBottom ? { marginBottom: '48px' } : {}}>
    <TablesBody>{children}</TablesBody>
  </TablesWrapper>
);

const ManageSingleRecording = ({
  history,
  location,
  recordings,
  locations,
}) => {
  const { recordingId } = useParams();
  const theme = useContext(ThemeContext);
  const { headingFont } = getFonts(theme);
  const [loading, setLoading] = useState(true);
  const [thisRecording, setThisRecording] = useState();
  const [locationData, setLocationData] = useState();
  const [historicUptimeData, setHistoricUptimeData] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: moment(getDayStart(new Date())).subtract(8, 'day').toISOString(),
    end: moment(getDayEnd(new Date())).subtract(1, 'day').toISOString(),
  });

  const fetchRecordingUptimeData = async (recordingId) => {
    setLoading(true);
    const data = await fetchRecordingUptimes(
      [recordingId],
      selectedPeriod.start,
      selectedPeriod.end,
    );
    if (data) {
      setHistoricUptimeData(data?.segments);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (thisRecording) {
      fetchRecordingUptimeData(recordingId);

      if (!thumbnail) {
        fetchThumbnailsForRecordings([thisRecording.id]).then((thumbnails) =>
          setThumbnail(thumbnails.get(thisRecording.id)[0]),
        );
      }
    }
  }, [thisRecording, selectedPeriod]);

  useEffect(() => {
    if (!thisRecording) {
      setThisRecording(recordings.find((r) => r.id === recordingId));
    }
  }, []);

  useEffect(() => {
    if (thisRecording && !locationData) {
      setLocationData(
        locations.find((l) => l.id === thisRecording.location.id),
      );
    }
  }, [thisRecording]);

  useEffect(() => {
    if (historicUptimeData && thisRecording && locationData) {
      setLoading(false);
    }
  }, [historicUptimeData, thisRecording, locationData]);

  // once everything is loaded, set the location state for the menu
  useEffect(() => {
    if (!loading) {
      history.replace(`${SETTINGS_MANAGE_SINGLE_RECORDING}/${recordingId}`, {
        ...location.state,
        location: locationData,
        recording: thisRecording,
      });
    }
  }, [loading]);

  const handleSelectedPeriod = useCallback((value) => {
    setSelectedPeriod(value);
  }, []);

  return (
    <Layout>
      <SettingsPageHeader history={history} />
      <Wrapper>
        {loading ? (
          <WrapperHandler>
            <Spinner />
          </WrapperHandler>
        ) : (
          <>
            <WrapperHandler key="image-wrapper" marginBottom>
              <H1 style={{ fontFamily: headingFont, marginBottom: '28px' }}>
                {thisRecording?.name &&
                  firstLetterUppercase(thisRecording?.name)}
              </H1>
              {thumbnail ? (
                <img
                  alt={`${thisRecording?.name} Camera Preview`}
                  src={thumbnail.url}
                  width={'100%'}
                />
              ) : (
                <div
                  style={{
                    height: '950px',
                    width: '100%',
                    background: 'transparent',
                  }}
                ></div>
              )}
            </WrapperHandler>
            <WrapperHandler key="graph-wrapper">
              <HeaderRow>
                <H1 style={{ fontFamily: headingFont, marginBottom: '28px' }}>
                  Camera Uptime
                </H1>
                <SimpleDatePicker
                  selectedPeriod={selectedPeriod}
                  updateSelected={handleSelectedPeriod}
                />
              </HeaderRow>
              {historicUptimeData?.length > 0 ? (
                <ResponsiveContainer width="100%" height={500}>
                  <LineChart
                    data={historicUptimeData.map((d) => ({
                      ...d,
                      uptime: 100 * d.uptime,
                      quality: 100 * d.quality,
                      index: `${getLabelForDate(d.index)} ${getLabelForTime(
                        d.index,
                      )}`,
                    }))}
                  >
                    <Line
                      type="monotone"
                      strokeWidth="2"
                      activeDot={{ r: 6 }}
                      key="index"
                      name="uptime"
                      stroke={theme.chartColors[0]}
                      dot={{
                        r: 0,
                        fill: theme.chartColors[0],
                      }}
                      dataKey="uptime"
                    />
                    <Line
                      type="monotone"
                      strokeWidth="2"
                      activeDot={{ r: 6 }}
                      key="index"
                      name="quality"
                      stroke={theme.chartColors[1]}
                      dot={{
                        r: 0,
                        fill: theme.chartColors[1],
                      }}
                      dataKey="quality"
                    />
                    <Tooltip />
                    <XAxis dataKey="index" />
                    <YAxis />
                  </LineChart>
                </ResponsiveContainer>
              ) : null}
            </WrapperHandler>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default ManageSingleRecording;
