import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Layout from '../../../../layouts/default-logged-in';
import SettingsPageHeader from '../../settings-page-header';
import { TablesBody, TablesWrapper, Wrapper } from '../../settings-page-styles';
import { getFonts } from '../../../../../styles/variables';

import {
  LineChart,
  ResponsiveContainer,
  Line,
  Tooltip,
  YAxis,
  XAxis,
} from 'recharts';

import styled, { ThemeContext } from 'styled-components';
import { CameraServerTable } from '../shared/components/camera-server-table';
import {
  fetchLocationUptimes,
  fetchRecordingUptimeStatus,
} from '../../../../../actions/uptime';
import { cameraServer } from '../../../../../services/api';
import { Button, H1, Icon, Spinner } from '@blueprintjs/core';
import EditLocationModal from '../../../../edit-location-modal';
import { userCanManageLocations } from '../../../../../services/auth-pkg-user';
import { SETTINGS_MANAGE_SINGLE_LOCATION } from '../../../../../constants/routes';
import {
  getDayEnd,
  getDayStart,
  getLabelForDate,
  getLabelForTime,
} from '../../../../../utils/dates';
import { fetchThumbnailsForRecordings } from '../../../../../actions/thumbnails';
import moment from 'moment';
import SimpleDatePicker from '../../../../date-and-comparison-date-selector/simple-date-picker';

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
`;
const HeaderContainer = styled.div`
  max-width: var(--widget-max-width);
  margin: 0 auto;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ManageSingleLocation = ({
  history,
  location,
  updateLocation,
  recordings = [],
  locations = [],
}) => {
  const { locationId } = useParams();

  const [loading, setLoading] = useState(true);
  const [recordingsInLocation, setRecordingsInLocation] = useState();
  const [uptimeData, setUptimeData] = useState();
  const [thisLocation, setThisLocation] = useState(
    locations.find((l) => l.id === locationId),
  );
  const [cameraServerData, setCameraServerData] = useState();
  const [historicUptimeData, setHistoricUptimeData] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [canManageLocation, setCanManageLocation] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: moment(getDayStart(new Date())).subtract(8, 'day').toISOString(),
    end: moment(getDayEnd(new Date())).subtract(1, 'day').toISOString(),
  });

  const theme = useContext(ThemeContext);
  const { headingFont } = getFonts(theme);

  // assign the recordings to the camera servers
  const assignRecordingsToCameraServers = (
    recordingsInLocation,
    cameraServers,
    uptimeData,
  ) => {
    const byCameraServer = {};

    recordingsInLocation.forEach((recording) => {
      // find the camera server
      const foundCS = cameraServers.find(
        (cameraServer) => cameraServer.id === recording.cameraServer,
      );

      const foundUptime = uptimeData.find(
        (uptime) => uptime.recording_id === recording.id,
      );

      const mergedUptime = {
        ...foundUptime,
        id: recording.id,
        name: recording.name,
        timezone: recording.timezone,
        thumbnails: recording.thumbnails,
      };

      if (
        byCameraServer[recording.cameraServer] &&
        byCameraServer[recording.cameraServer].recordings
      ) {
        byCameraServer[recording.cameraServer].recordings.push(mergedUptime);
      } else {
        byCameraServer[recording.cameraServer] = {
          id: foundCS ? foundCS.id : 'unknown',
          name: foundCS ? foundCS.name : 'Unknown Camera Server',
          recordings: [mergedUptime],
        };
      }
    });

    return byCameraServer;
  };

  const fetchThumbnails = async (locationId, recordings) => {
    const filteredRecordings = recordings.filter(
      (r) => r.location.id === locationId,
    );

    const thumbnails = await fetchThumbnailsForRecordings(
      filteredRecordings.map((r) => r.id),
    );

    setRecordingsInLocation(
      filteredRecordings.map((r) => ({
        ...r,
        thumbnails: thumbnails.get(r.id),
      })),
    );
  };

  const fetchUptimeData = async (recordingsInLocation) => {
    const uptimes = await fetchRecordingUptimeStatus(
      recordingsInLocation.map((r) => r.id),
    );
    setUptimeData(uptimes);
  };

  const fetchHistoricUptimeData = async (locationId) => {
    if (!loading) setLoading(true);
    console.log('fetching historic uptime data');
    const data = await fetchLocationUptimes(
      [locationId],
      selectedPeriod.start,
      selectedPeriod.end,
    );
    if (data) {
      setHistoricUptimeData(data?.segments || []);
      setLoading(false);
    }
  };

  const useData = async (uptimeData, recordings) => {
    const cameraServers = await cameraServer.list();
    const byCameraServer = assignRecordingsToCameraServers(
      recordingsInLocation,
      cameraServers,
      uptimeData,
    );
    setCameraServerData(byCameraServer);
  };

  // Fetch thumbnails and set the current recordings for the location
  useEffect(() => {
    if (recordings.length) {
      fetchThumbnails(locationId, recordings);
    }
  }, [recordings]);

  // Set the current location
  useEffect(() => {
    if (!thisLocation && locations.length) {
      setThisLocation(locations.find((l) => l.id === locationId));
    }
  }, [locations]);

  // Fetch the historic uptime data for the location graph
  useEffect(() => {
    if (thisLocation && recordingsInLocation && recordingsInLocation.length) {
      fetchHistoricUptimeData(locationId);
    }
  }, [thisLocation, recordingsInLocation, selectedPeriod]);

  // Fetch the current uptime status for the table
  useEffect(() => {
    if (recordingsInLocation && recordingsInLocation.length) {
      fetchUptimeData(recordingsInLocation);
    }
  }, [recordingsInLocation]);

  // Once we have the uptime data, use it
  useEffect(() => {
    if (uptimeData && recordingsInLocation && recordingsInLocation.length) {
      useData(uptimeData, recordingsInLocation);
    }
  }, [uptimeData, recordingsInLocation]);

  useEffect(() => {
    const getAuth = async () =>
      setCanManageLocation(await userCanManageLocations([locationId]));
    getAuth();
  }, []);

  useEffect(() => {
    if (
      thisLocation &&
      recordingsInLocation &&
      uptimeData &&
      historicUptimeData &&
      cameraServerData
    ) {
      setLoading(false);
    }
  }, [
    thisLocation,
    recordingsInLocation,
    uptimeData,
    historicUptimeData,
    cameraServerData,
  ]);

  // once everything is loaded, set the location state for the menu
  useEffect(() => {
    if (!loading) {
      history.replace(`${SETTINGS_MANAGE_SINGLE_LOCATION}/${locationId}`, {
        ...location.state,
        location: thisLocation,
      });
    }
  }, [loading]);

  const toggleEditModal = useCallback(() => {
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen, setIsEditModalOpen]);

  const handleSelectedPeriod = useCallback((value) => {
    setSelectedPeriod(value);
  }, []);

  return (
    <Layout>
      <SettingsPageHeader history={history} />
      {thisLocation && (
        <EditLocationModal
          isOpen={isEditModalOpen}
          locationId={thisLocation.id}
          updateLocation={updateLocation}
          onClose={toggleEditModal}
          canManageLocations={canManageLocation}
        />
      )}
      <Wrapper>
        {thisLocation && (
          <HeaderContainer>
            <HeaderRow>
              <H1 style={{ marginBottom: '24px', fontFamily: headingFont }}>
                {thisLocation.name}
              </H1>
              <Button onClick={toggleEditModal}>
                Edit Location
                <Icon icon="edit" style={{ marginLeft: '8px' }} />
              </Button>
            </HeaderRow>
          </HeaderContainer>
        )}
        <TablesWrapper key="graph-container" style={{ marginBottom: '48px' }}>
          <TablesBody style={{ minHeight: '500px', width: '100%' }}>
            {loading && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
            {!loading && historicUptimeData?.length > 0 && (
              <>
                <HeaderRow>
                  <H1 style={{ marginBottom: '24px', fontFamily: headingFont }}>
                    Camera Uptime
                  </H1>
                  <SimpleDatePicker
                    selectedPeriod={selectedPeriod}
                    updateSelected={handleSelectedPeriod}
                  />
                </HeaderRow>
                <ResponsiveContainer width="100%" height={500}>
                  <LineChart
                    data={historicUptimeData.map((d) => ({
                      ...d,
                      uptime: 100 * d.uptime,
                      quality: 100 * d.quality,
                      index: `${getLabelForDate(d.index)} ${getLabelForTime(
                        d.index,
                      )}`,
                    }))}
                  >
                    <Line
                      type="monotone"
                      strokeWidth="2"
                      activeDot={{ r: 6 }}
                      key="index"
                      name="uptime"
                      stroke={theme.chartColors[0]}
                      dot={{
                        r: 0,
                        fill: theme.chartColors[0],
                      }}
                      dataKey="uptime"
                    />
                    <Line
                      type="monotone"
                      strokeWidth="2"
                      activeDot={{ r: 6 }}
                      key="index"
                      name="quality"
                      stroke={theme.chartColors[1]}
                      dot={{
                        r: 0,
                        fill: theme.chartColors[1],
                      }}
                      dataKey="quality"
                    />
                    <Tooltip />
                    <XAxis dataKey="index" />
                    <YAxis />
                  </LineChart>
                </ResponsiveContainer>
              </>
            )}
          </TablesBody>
        </TablesWrapper>
        {cameraServerData && typeof cameraServerData === 'object'
          ? Object.entries(cameraServerData).map(([csId, csData]) => (
              <TablesWrapper key={csId}>
                <TablesBody>
                  <CameraServerTable
                    key={csData.name}
                    recordings={csData.recordings}
                    name={csData.name}
                    locationId={locationId}
                    location={location}
                  />
                </TablesBody>
              </TablesWrapper>
            ))
          : null}
      </Wrapper>
    </Layout>
  );
};

export default ManageSingleLocation;
