import { useReducer, useRef, useCallback } from 'react';
import { periodPresetsT } from '../../models/period';
import {
  filterTypesArray,
  INFO_MESSAGES,
  INITIALLY_SELECTED_LOCATIONS,
  MAX_DAYS,
  REDUCER_ACTIONS,
  TOAST_TIMEOUTS,
  UPDATE_FILTER_OPTIONS_ACTIONS,
} from '../../constants/heatmaps';
import moment from 'moment';
import { getDayEnd, getDayStart } from '../../utils/dates';
import { findRecordings, isQuerySubset } from '../../utils/heatmapsHelpers';
import { heatmapsReducer } from '../../utils/heatmapsHelpers';
import React, { createContext, useContext } from 'react';
import useHeatmapBreakdownOptions from '../../hooks/use-heatmap-breakdown-options';
import { errorToast, infoToast } from '../../utils/toaster';

export type HierarchicalValuesArray = { key: String, values: [] } | String[];
export type KeyValuesArray = { key: String, values: HierarchicalValuesArray }[];

export type HeatmapFilterOptions = {
  location: { id: String, name: String }[] | undefined,
  recording: { id: String, name: String }[] | undefined,
  taxonomies: KeyValuesArray | undefined,
  breakdowns: KeyValuesArray,
  selectedBreakdowns: KeyValuesArray,
  type: String[],
  startDate: String,
  endDate: String,
  selectedDate: String,
  currentSelectedDatePreset: periodPresetsT,
};

const FilterOptionsContext = createContext(null);

export const FilterOptionsProvider = ({ children, locations, recordings, showStaffFilters, disableAgeAndGenderOnFrontEnd }) => {
  const getDateOffset = (offset) =>  moment(window.isDemoOrg ? '2024-12-01T00:00:00.000Z' : getDayStart(new Date())).subtract(offset, 'day').toISOString()
  
  // console.log('demo', window.isDemoOrg, getDateOffset(8), getDateOffset(1), filterTypesArray())

  const breakdowns = useHeatmapBreakdownOptions(showStaffFilters, disableAgeAndGenderOnFrontEnd, filterTypesArray()[0])

  const [state, dispatch] = useReducer(heatmapsReducer, {
    filterOptions: {
      locations: locations && locations.length ? [locations[0].id] : [],
      recordings: findRecordings(locations[0]?.id, recordings).map((r) => r.id),
      taxonomies: [],
      breakdowns,
      selectedBreakdowns: [{ key: breakdowns[0].key, values: [breakdowns[0].values[0]] }],
      type: filterTypesArray()[0],
      start: getDateOffset(7),
      end: getDateOffset(1),
      selectedDate: getDateOffset(1)
    },
    taxonomyList: [],
    taxonomyKeyStrings: [],
    cardSize: 1,
    prevFetchedFilterOptions: undefined,
    shouldFetchHeatmaps: false,
    isQuerySubset: false,
    heatmaps: [],
    filteredRecordings: [],
    enabledLocations: locations.map((l) => l.id),
    ctx: null,
  });

  const ctxFetched = useRef(false);

  const calculateEntitiesToSelect = (ctx, filterOptions, newValues, type) => {
    try {
      // find all locations and recordings with taxonomy
      const withTaxonomy = ctx
        .filter((c) =>
          type === 'taxonomy'
            ? newValues.includes(c.taxonomy)
            : filterOptions.taxonomies.includes(c.taxonomy) &&
              newValues.includes(c.location_id),
        )
        .map(({ recording_id, location_id }) => ({
          recording_id,
          location_id,
        }));

      // generate a list of unique locations with taxonomy
      const locationsWithTaxonomy = [
        ...new Set(withTaxonomy.map((c) => c.location_id)),
      ];

      // generate a list of unique recordings with taxonomy
      const recordingsToSelect = [
        ...new Set(
          withTaxonomy
            .filter(({ location_id }) =>
              locationsWithTaxonomy.includes(location_id),
            )
            .map(({ recording_id }) => recording_id),
        ),
      ];

      return {
        recordings: recordingsToSelect,
        locations: locationsWithTaxonomy,
      };
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const updateFilterOptions = useCallback(
    (type, value, recordingsData = recordings) => {
      switch (type) {
        case UPDATE_FILTER_OPTIONS_ACTIONS.LOCATIONS: {
          if (state.filterOptions?.taxonomies?.length > 0) {
            // here we want to update the recordings to match the selected locations
            // and we also want to ensure the recordings include selected taxonomies

            const calculatedEntities = calculateEntitiesToSelect(
              state.ctx,
              state.filterOptions,
              value,
            );

            const newFilterOptions = {
              ...state.filterOptions,
              ...calculatedEntities,
            };

            dispatch({
              type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
              payload: newFilterOptions,
            });
          } else {
            // no taxonomies selected so we can just update the locations
            // and match the recordings to the selected locations
            const newFilterOptions = {
              ...state.filterOptions,
              locations: [...new Set(value)],
              recordings: findRecordings(value, recordingsData).map(
                (r) => r.id,
              ),
            };
            dispatch({
              type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
              payload: newFilterOptions,
            });
          }
          break;
        }

        case UPDATE_FILTER_OPTIONS_ACTIONS.RECORDINGS:
          dispatch({
            type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
            payload: { ...state.filterOptions, recordings: value },
          });
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.TAXONOMIES: {
          if (value.length === 0) {
            dispatch({
              type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
              payload: {
                ...state.filterOptions,
                locations: [locations[0]],
                recordings: findRecordings(locations[0]?.id, recordingsData),
                taxonomies: [],
              },
            });
            break;
          } else {
            const calculatedEntities = calculateEntitiesToSelect(
              state.ctx,
              state.filterOptions,
              value,
              'taxonomy',
            );
            // only select the first 10 locations
            // when changing taxonomies
            // you can select more when from the locations menu
            const newFilterOptions = {
              ...state.filterOptions,
              // locations: calculatedEntities.locations.splice(
              //   0,
              //   INITIALLY_SELECTED_LOCATIONS,
              // ),
              locations: calculatedEntities.locations,
              recordings: calculatedEntities.recordings,
              taxonomies: value,
            };
            dispatch({
              type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
              payload: newFilterOptions,
            });
          }
          break;
        }

        case UPDATE_FILTER_OPTIONS_ACTIONS.BREAKDOWN:
          dispatch({
            type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
            payload: { ...state.filterOptions, selectedBreakdowns: value },
          });
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.TYPE:
          const breakdowns = useHeatmapBreakdownOptions(showStaffFilters, disableAgeAndGenderOnFrontEnd, value)

          dispatch({
            type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
            payload: { 
              ...state.filterOptions, 
              breakdowns,
              selectedBreakdowns: [{ key: breakdowns[0].key, values: [breakdowns[0].values[0]] }],
              type: value 
            },
          });
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.SELECTED_PERIOD:
          const daysDiff = Math.abs(moment(value.start).diff(moment(value.end), 'days'));
          console.log('Days difference:', daysDiff);
          if (daysDiff >= MAX_DAYS) {
            errorToast({
              message: INFO_MESSAGES.PERIOD_TOO_LONG,
              timeout: TOAST_TIMEOUTS.LONG,
            });
            return;
          } else {
            const newFilterOptions = {
              ...state.filterOptions,
              start: moment(getDayStart(new Date(value.start))).toISOString(),
              end: moment(getDayEnd(new Date(value.end))).toISOString(),
              selectedDate: moment(
                getDayStart(new Date(value.end)),
              ).toISOString(),
            };
            dispatch({
              type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
              payload: newFilterOptions,
            });
          }
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.SELECTED_DATE:
          dispatch({
            type: REDUCER_ACTIONS.SET_SELECTED_DATE,
            payload: value,
          });
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.CARD_SIZE:
          dispatch({ type: REDUCER_ACTIONS.SET_CARD_SIZE, payload: value });
          break;

        case UPDATE_FILTER_OPTIONS_ACTIONS.RESET: {
          const newFilterOptions = {
            ...state.filterOptions,
            locations: [locations[0].id],
            recordings: findRecordings(locations[0].id, recordingsData).map(
              (rec) => rec.id,
            ),
            taxonomies: [],
            selectedBreakdowns: [{ key: '', values: ['all visitors'] }],
          };
          dispatch({
            type: REDUCER_ACTIONS.SET_FILTER_OPTIONS,
            payload: newFilterOptions,
          });
          dispatch({
            type: REDUCER_ACTIONS.SET_FILTERED_RECORDINGS,
            payload: findRecordings([locations[0].id], recordingsData),
          });
          break;
        }

        default:
          if (Object.values(REDUCER_ACTIONS).includes(type)) {
            dispatch({ type, payload: value });
          }
          break;
      }

      // Update query subset status and fetch flag
      if (type !== REDUCER_ACTIONS.SET_IS_QUERY_SUBSET) {
        const isSubset = isQuerySubset(
          type,
          state.prevFetchedFilterOptions,
          value,
          state.filterOptions,
        );
        dispatch({
          type: REDUCER_ACTIONS.SET_IS_QUERY_SUBSET,
          payload: isSubset,
        });
      }
    },
    [state, locations, recordings],
  );

  return (
    <FilterOptionsContext.Provider
      value={{
        state,
        updateFilterOptions,
        ctxFetched,
      }}
    >
      {children}
    </FilterOptionsContext.Provider>
  );
};

export const useFilterOptionsContext = () => {
  const context = useContext(FilterOptionsContext);

  if (!context) {
    throw new Error(
      'useFilterOptionsContext must be used within a FilterOptionsProvider',
    );
  }
  return context;
};

export const useHeatmapDisplayGridContext = () => {
  const context = useContext(FilterOptionsContext);
  const { cardSize, prevFetchedFilterOptions } = context.state;
  // when the date range is changed
  // if the date range is outside of the previously fetched date range
  // then show no heatmaps out of range message
  // we can't fully decouple this from the display grid
  // as the date range is used in the heatmap display grid
  // de-coupling this will confuse users

  const { selectedDate } = context.state.filterOptions;
  if (!context) {
    throw new Error(
      'useHeatmapDisplayGridContext must be used within a FilterOptionsProvider',
    );
  }
  return {
    cardSize,
    selectedDate,
    taxonomies: prevFetchedFilterOptions?.taxonomies || [],
    prevFetchedFilterOptions,
  };
};
