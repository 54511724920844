// @flow

import defaultMoment from 'moment';
import { AGGREGATION_PERIOD } from '../constants/graph-options';
import { extendMoment } from 'moment-range';
import { blankFormat } from '../components/date-and-comparison-date-selector/dates-presets-config';
import {
  dateFormatHours,
  dateFormatHoursAndMins,
  dateFormatMonths,
  datePickerLabelformat,
  standardDateFormat,
  calendarHeatmapFormat,
} from '../constants/dates-formats';

import * as PeriodModel from '../models/period';
import * as QueryApiRequestModel from '../models/query-api-request';
import type {
  periodPresetConfigItemT,
  previousPeriodPresetConfigItemT,
} from '../components/date-and-comparison-date-selector/dates-presets-config';

const moment = extendMoment(defaultMoment);

export const getDaysBetweenDates = (start: string, end: string): number => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return duration.asDays();
};

export const getIndexesBetweenDates = (
  start: string,
  end: string,
  aggregation?: string,
): number => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  let result = 0;

  const getHours = () => {
    const endDay = moment(end)
      .add(1, 'day')
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    const startDay = moment(start).hour(0).minute(0).second(0).millisecond(0);
    const hoursDuration = moment.duration(endDay.diff(startDay));
    return hoursDuration.asHours();
  };

  if (aggregation) {
    switch (aggregation.toLowerCase()) {
      case AGGREGATION_PERIOD.HOUR_OF_DAY:
        return 24;
      case AGGREGATION_PERIOD.QUARTER_HOUR: {
        result = getHours() * 4;
        break;
      }
      case AGGREGATION_PERIOD.HOUR: {
        result = getHours();
        break;
      }
      case AGGREGATION_PERIOD.WEEK_ISO: {
        const startDate = moment(start).startOf('week').add(1, 'week');
        const endDate = moment(end);

        const mondayStartWeek = startDate.isoWeekday(1);
        const sundayEndWeek = endDate.isoWeekday(7);

        const range = moment.range(mondayStartWeek, sundayEndWeek);
        const result = Array.from(range.by('days'));

        return result.length / 7;
      }
      case AGGREGATION_PERIOD.WEEK_US: {
        const startDate = moment(start)
          .locale('en')
          .startOf('week')
          .add(1, 'week');
        const endDate = moment(end);

        const sundayStartWeek = startDate.isoWeekday(7);
        const saturdayEndWeek = endDate.isoWeekday(6);

        const range = moment.range(sundayStartWeek, saturdayEndWeek);
        const result = Array.from(range.by('days'));

        return result.length / 7;
      }
      case AGGREGATION_PERIOD.MONTH: {
        const dateStart = moment(start);
        const dateEnd = moment(end);
        const startYear = Number(dateStart.format('YYYY'));
        const startMonth = Number(dateStart.format('MM'));
        const endYear = Number(dateEnd.format('YYYY'));
        const endMonth = Number(dateEnd.format('MM'));

        const yearMonths = (endYear - startYear) * 12;
        const months = endMonth - startMonth;
        const total = yearMonths + months + 1;

        return total;
      }
      case AGGREGATION_PERIOD.DAY_OF_WEEK: {
        return 7;
      }
      case AGGREGATION_PERIOD.COMBINED:
      case AGGREGATION_PERIOD.DAY:
      default: {
        result = duration.asDays() + 1;
        break;
      }
    }
  } else {
    result = duration.asDays() + 1;
  }

  return Math.round(result) === 0 ? Math.ceil(result) : Math.round(result);
};

export const ordinalSuffix = (val: number | string): string => {
  const i = Number(val);
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const dayOfWeekLabel = (
  day: number | string,
  short?: boolean,
): ?string => {
  const i = Number(day);
  if (i === 0) return short ? 'S' : 'Sunday';
  if (i === 1) return short ? 'M' : 'Monday';
  if (i === 2) return short ? 'T' : 'Tuesday';
  if (i === 3) return short ? 'W' : 'Wednesday';
  if (i === 4) return short ? 'T' : 'Thursday';
  if (i === 5) return short ? 'F' : 'Friday';
  if (i === 6) return short ? 'S' : 'Saturday';
  return null;
};

export const weekLabel = (value: string, short: boolean): string => {
  const date = moment(value).format('ddd Do MMM');
  if (short) return date;
  return `Week commencing ${date}`;
};

export const dowHodLabel = (value: string, short?: boolean): string => {
  const [day, hour] = value.split('-');
  return `${hourOfDayLabel(hour)}, ${dayOfWeekLabel(day, short) || ''}`;
};

export const hourOfDayLabel = (hour: number | string): string => {
  const i = Number(hour);
  if (i === 0) return 'Midnight';
  if (i === 12) return 'Midday';
  if (i < 12) return `${i}am`;
  return `${i - 12}pm`;
};

export const formatDateStr = 'MMM DD, YYYY';

export const getLabelForDate = (date: string): string =>
  new Date(date).toLocaleDateString();

export const getLabelForTime = (date: string): string =>
  new Date(date).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });

export const getLabelForDateRange = (
  startDate: string,
  endDate: string,
): string => {
  return `${moment(startDate).format(formatDateStr)} - ${moment(endDate).format(
    formatDateStr,
  )}`;
};

export const getFormatDateByAggregation = (
  date: Date | string,
  aggregation?: QueryApiRequestModel.aggregationEnumT,
  dayOfWeekIndex?: number,
): string => {
  let format;
  if (aggregation) {
    switch (aggregation.toLowerCase()) {
      case AGGREGATION_PERIOD.HOUR_OF_DAY:
        format = 'HH';
        break;
      case AGGREGATION_PERIOD.QUARTER_HOUR:
        format = dateFormatHoursAndMins;
        break;
      case AGGREGATION_PERIOD.HOUR:
        format = dateFormatHours;
        break;
      case AGGREGATION_PERIOD.MONTH:
        format = dateFormatMonths;
        break;
      case AGGREGATION_PERIOD.DAY_OF_WEEK:
        return String(
          typeof dayOfWeekIndex === 'number' &&
            dayOfWeekIndex >= 0 &&
            dayOfWeekIndex + 1,
        );
      case AGGREGATION_PERIOD.COMBINED:
      case AGGREGATION_PERIOD.DAY:
      case AGGREGATION_PERIOD.WEEK_ISO:
      case AGGREGATION_PERIOD.WEEK_US:
      default:
        return getStandardFormatDate(date);
    }
  } else {
    return getStandardFormatDate(date);
  }

  return moment(date).format(format);
};

export const getStandardFormatDate = (date: Date | string): string =>
  moment(date).format(standardDateFormat);

export const getDatesListInRangePeriod = (
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  isDisplay: boolean = true,
  startOfWeek?: 'sunday' | 'monday',
): string[] => {
  if (aggregation.toLowerCase() === AGGREGATION_PERIOD.DAY_OF_WEEK) {
    if (startOfWeek === 'sunday') {
      return ['0', '1', '2', '3', '4', '5', '6'];
    } else {
      return ['1', '2', '3', '4', '5', '6', '0'];
    }
  } else if (aggregation.toLowerCase() === AGGREGATION_PERIOD.DOW_HOD) {
    const labels = [];
    ['1', '2', '3', '4', '5', '6', '0'].forEach((day) =>
      Array.from(Array(24).keys()).forEach((hour) =>
        labels.push(`${day}-${hour}`),
      ),
    );
    return labels;
  }
  const indexesBetweenDates = getIndexesBetweenDates(
    period.start,
    period.end,
    aggregation,
  );
  const sectionsUnit = formatAggregationToMomentKey(aggregation);
  const datesRangeList =
    indexesBetweenDates >= 1
      ? Array.from(Array(indexesBetweenDates).keys()).map((index) => {
          let momentDate = moment.utc(period.start);

          if (aggregation === AGGREGATION_PERIOD.WEEK_ISO) {
            momentDate = momentDate.startOf('week').add(1, 'week');
          } else if (aggregation === AGGREGATION_PERIOD.WEEK_US) {
            momentDate = momentDate.locale('en').startOf('week').add(1, 'week');
          }

          if (!isDisplay && aggregation === AGGREGATION_PERIOD.MONTH) {
            return momentDate
              .add(index * sectionsUnit.multiples, sectionsUnit.unit)
              .toISOString();
          }

          const date = getFormatDateByAggregation(
            momentDate.add(index * sectionsUnit.multiples, sectionsUnit.unit),
            aggregation,
            index,
          );
          return date;
        })
      : [];

  return datesRangeList;
};

export const formatAggregationToMomentKey = (
  aggregation: QueryApiRequestModel.aggregationEnumT,
): {
  unit: string,
  multiples: number,
} => {
  switch (aggregation.toLowerCase()) {
    case AGGREGATION_PERIOD.HOUR_OF_DAY:
    case AGGREGATION_PERIOD.HOUR:
      return { unit: 'hours', multiples: 1 };
    case AGGREGATION_PERIOD.WEEK_ISO:
    case AGGREGATION_PERIOD.WEEK_US:
      return { unit: 'weeks', multiples: 1 };
    case AGGREGATION_PERIOD.MONTH:
      return { unit: 'months', multiples: 1 };
    case AGGREGATION_PERIOD.QUARTER_HOUR:
      return { unit: 'minutes', multiples: 15 };
    case AGGREGATION_PERIOD.DAY_OF_WEEK:
    case AGGREGATION_PERIOD.COMBINED:
    case AGGREGATION_PERIOD.DAY:
    default:
      return { unit: 'days', multiples: 1 };
  }
};

export const getDateRangeLabelFormat = (
  period: QueryApiRequestModel.periodT,
  presetConfig: periodPresetConfigItemT | previousPeriodPresetConfigItemT,
): string => {
  const { start, end } = period;
  const { format } = presetConfig;

  // use default date picker label format
  if (!format || !format.startDate || !format.endDate) {
    const { start, end } = period;
    const startLabel = moment(start).format(datePickerLabelformat);
    const endLabel = moment(end).format(datePickerLabelformat);

    if (startLabel === endLabel) {
      return startLabel;
    }
    return `${startLabel} - ${endLabel}`;
  }

  if (format.startDate !== blankFormat && format.endDate !== blankFormat) {
    const startLabel = moment(start).format(format.startDate);
    const endLabel = moment(end).format(format.endDate);

    if (startLabel === endLabel) {
      return startLabel;
    }
    return `${startLabel} - ${endLabel}`;
  } else if (
    format.startDate === blankFormat &&
    format.endDate !== blankFormat
  ) {
    const endLabel = moment(end).format(format.endDate);

    return endLabel;
  } else if (
    format.startDate !== blankFormat &&
    format.endDate === blankFormat
  ) {
    const startLabel = moment(start).format(format.startDate);

    return startLabel;
  }

  if (start === end) {
    return start;
  }
  return `${start} - ${end}`;
};

export const getDateRangeFromPresetPeriod = (
  period?: PeriodModel.t | PeriodModel.comparePeriodT,
): [Date | null, Date | null] => {
  let dateRange = [null, null];

  if (period && period.selectedDates) {
    if (period.selectedDates.start) {
      dateRange[0] = moment(period.selectedDates.start).toDate();
    }

    if (period.selectedDates.end) {
      dateRange[1] = moment(period.selectedDates.end).toDate();
    }
  }
  return dateRange;
};

type uploadingScheduleItemT = {
  day: string,
  start: string,
  stop: string,
};
type uploadingScheduleT = uploadingScheduleItemT[];
export const isCurrentDateInSchedule = (
  schedule: uploadingScheduleT,
  timezone: string,
): boolean => {
  const currentTime = moment().tz(timezone);
  const day = moment(currentTime).format('ddd');
  const scheduleMatch = schedule.find((scheduleConfig) => {
    return scheduleConfig.day === day;
  });

  if (!scheduleMatch) return false;

  const [startHour, startMinute] = scheduleMatch.start.split(':');
  const [stopHour, stopMinute] = scheduleMatch.stop.split(':');
  const openingTime = moment(currentTime)
    .hour(startHour)
    .minute(startMinute)
    .second(0);
  const closingTime = moment(currentTime)
    .hour(stopHour)
    .minute(stopMinute)
    .second(0);
  const isDateInSchedule =
    currentTime.isAfter(openingTime) && currentTime.isBefore(closingTime);

  if (isDateInSchedule) {
    return true;
  }

  return false;
};

export const getFormattedHeatmapDate = (index: number | string) => {
  return moment(index).format(calendarHeatmapFormat);
};

export const getDayStart = (d: Date) => {
  const dAsDate = new Date(d);
  dAsDate.setUTCHours(0, 0, 0, 0);
  return dAsDate;
};

export const getDayEnd = (d: Date) => {
  const dAsDate = new Date(d);
  dAsDate.setUTCHours(23, 59, 59, 999);
  return dAsDate;
};
