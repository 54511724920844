import { Toaster, Intent } from '@blueprintjs/core';

const toastMessagesList = [];

const toaster = Toaster.create();

const errorToast = (args) => {
  const messageHasBeenUsed = toastMessagesList.includes(args.message);

  if (!messageHasBeenUsed) {
    toastMessagesList.push(args.message);

    setTimeout(() => {
      const index = toastMessagesList.indexOf(args.message);
      toastMessagesList.splice(index, 1);
    }, 1000);

    toaster.show({
      intent: Intent.WARNING,
      ...args,
    });
  }
};

const successToast = (args) => {
  const messageHasBeenUsed = toastMessagesList.includes(args.message);

  if (!messageHasBeenUsed) {
    toastMessagesList.push(args.message);

    setTimeout(() => {
      const index = toastMessagesList.indexOf(args.message);
      toastMessagesList.splice(index, 1);
    }, 1000);

    toaster.show({
      intent: Intent.SUCCESS,
      ...args,
    });
  }
};

const infoToast = (args) => {
  const messageHasBeenUsed = toastMessagesList.includes(args.message);

  if (!messageHasBeenUsed) {
    toastMessagesList.push(args.message);

    setTimeout(() => {
      const index = toastMessagesList.indexOf(args.message);
      toastMessagesList.splice(index, 1);
    }, 1000);

    toaster.show({
      intent: Intent.INFO,
      ...args,
    });
  }
};


const connectionFailedToast = () => {
  errorToast({
    action: {
      href: '',
      text: <strong>Refresh Page</strong>,
    },
    message:
      'The server is currently unreachable. Please try refreshing the page, or contact support if the issue continues.',
    timeout: 0,
  });
};

export { errorToast, successToast, infoToast, connectionFailedToast };
export default toaster;
