import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  margin: 0;
`;

export const AuraSelect = ({
  activeItem,
  textSelector,
  keySelector,
  items,
  defaultText,
  handleChange,
  activeColour,
  isActive,
}) =>
  items.length > 1 ? (
    <Select
      activeItem={activeItem}
      items={items}
      itemRenderer={(item, { handleClick }) => (
        <MenuItem
          key={item[keySelector]}
          text={item[textSelector]}
          onClick={handleClick}
        />
      )}
      onItemSelect={handleChange}
      filterable={false}
    >
      <Button
        text={
          activeItem
            ? activeItem[textSelector]
            : defaultText
            ? defaultText
            : 'Select an Option'
        }
        rightIcon="double-caret-vertical"
        style={
          isActive && activeColour ? { backgroundColor: activeColour } : {}
        }
      />
    </Select>
  ) : (
    <P>{items[0][textSelector]}</P>
  );

const AuraSearch = ({
  activeItem,
  textSelector,
  keySelector,
  items,
  defaultText,
  handleChange,
  activeColour,
  isActive,
}) => (
  <Select
    activeItem={activeItem}
    items={items}
    itemRenderer={(item, { handleClick }) => (
      <MenuItem
        key={item[keySelector]}
        text={item[textSelector]}
        onClick={handleClick}
      />
    )}
    itemPredicate={(query, item) =>
      item[textSelector].toLowerCase().includes(query.toLowerCase())
    }
    onItemSelect={handleChange}
    filterable={true}
  >
    <Button
      text={
        activeItem
          ? activeItem[textSelector]
          : defaultText
          ? defaultText
          : 'Select an Option'
      }
      rightIcon="double-caret-vertical"
      style={isActive && activeColour ? { backgroundColor: activeColour } : {}}
      intent={isActive ? Intent.PRIMARY : ''}
    />
  </Select>
);

export default AuraSearch;
