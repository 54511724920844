import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Cell,
  TagContainer,
  TagRow,
  StyledTag,
  LoadingSpinner,
  CenteredOverlay,
  ImageContainer,
} from './styled';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';
import { Tag } from '@blueprintjs/core';
import { HEATMAP_KEY_COLOURS } from '../../constants/heatmaps';
import { NoItems } from '../filter-menu/styled';
import moment from 'moment';

type HeatmapItem = {
  url: String,
  heatmap_id: String,
  age: String,
  gender: String,
  role: String,
  local_time: String,
};
type Props = {
  location_name: String,
  recording_name: String,
  taxonomies?: String[],
  ages?: String,
  genders?: String,
  roles?: String,
  heatmap_type: String,
  visibleTaxonomies: String[],
  heatmaps: HeatmapItem[],
  size: Number,
  selectedDate: Date,
};

const getDisplayTaxonomies = (taxonomy_names, visibleTaxonomies) =>
  taxonomy_names.filter((taxonomy_name) =>
    visibleTaxonomies.includes(taxonomy_name),
  );

const DisplayTags = ({
  location_name,
  recording_name,
  taxonomy_names,
  ages,
  genders,
  roles,
}) => (
  <TagContainer>
    {location_name && (
      <StyledTag bgColor={HEATMAP_KEY_COLOURS.location}>
        {location_name}
      </StyledTag>
    )}
    {recording_name && (
      <StyledTag bgColor={HEATMAP_KEY_COLOURS.recording}>
        {recording_name}
      </StyledTag>
    )}
    {!!taxonomy_names &&
      taxonomy_names?.length > 0 &&
      taxonomy_names.map((taxonomy_name) => (
        <StyledTag bgColor={HEATMAP_KEY_COLOURS.taxonomy}>
          {taxonomy_name}
        </StyledTag>
      ))}
    {ages && ages !== '' && <Tag>{ages}</Tag>}
    {genders && genders !== '' && <Tag>{firstLetterUppercase(genders)}</Tag>}
    {roles && roles !== '' && <Tag>{firstLetterUppercase(roles)}</Tag>}
    {(!ages || ages === '') &&
      (!genders || genders === '') &&
      (!roles || roles === '') && <Tag>All Visitors</Tag>}
  </TagContainer>
);

const HeatmapCard = ({
  location_name,
  recording_name,
  ages,
  genders,
  roles,
  taxonomies,
  visibleTaxonomies,
  heatmaps,
  selectedDate,
  size,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = useRef(null);
  const loadedImages = useRef(new Set());

  useEffect(() => {
    setIsLoading(true);

    if (imageRef.current) {
      const imgUrl = heatmaps.find((h) => moment(h.local_time).isSame(selectedDate, 'day'))?.url;
      if (imgUrl && loadedImages.current.has(imgUrl)) {
        setIsLoading(false);
      } else if (imageRef.current.complete) {
        setIsLoading(false);
      }
    }
  }, [selectedDate, heatmaps]);

  const handleImageLoad = () => {
    const imgUrl = heatmaps.find((h) => moment(h.local_time).isSame(selectedDate, 'day'))?.url;
    if (imgUrl) {
      loadedImages.current.add(imgUrl);
    }
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  // Only change these if the props change
  const displayTags = useMemo(
    () => (
      <TagRow>
        <DisplayTags
          location_name={location_name}
          recording_name={recording_name}
          taxonomy_names={
            visibleTaxonomies?.length > 0
              ? getDisplayTaxonomies(taxonomies, visibleTaxonomies)
              : []
          }
          ages={ages}
          genders={genders}
          roles={roles}
        />
      </TagRow>
    ),
    [location_name, recording_name, visibleTaxonomies, ages, genders, roles],
  );

  // Only render the image if it's for the selected date
  // maintains card positioning

  const imgUrl = useMemo(
    () =>
      heatmaps.find((h) => moment(h.local_time).isSame(selectedDate, 'day'))
        ?.url,
    [selectedDate, heatmaps],
  );
  return (
    <Cell size={size}>
      {displayTags}
      <ImageContainer size={size}>
        {isLoading && typeof imgUrl === 'string' && (
          <CenteredOverlay>
            <LoadingSpinner size={30} />
          </CenteredOverlay>
        )}
        {typeof imgUrl !== 'string' && (
          <NoItems>Unavailable</NoItems>
        )}
        {typeof imgUrl === 'string' && (
          <img
            ref={imageRef}
            src={imgUrl}
            width="100%"
            loading="eager"
            alt={'Heatmap'}
            onLoad={handleImageLoad}
            onError={handleImageError}
            style={{ visibility: isLoading ? 'hidden' : 'visible' }}
          />
        )}
      </ImageContainer>
    </Cell>
  );
};

export default HeatmapCard;
