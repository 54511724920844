import { errorToast } from '../utils/toaster';
import { sendMessage } from '../services/sql-api';

export const getSourceUrl = async (recordingId, heatmapId) => {
  try {
    const response = await heatmaps.getSourceUrl(heatmapId);

    const payload = {
      recordingId,
      heatmapId,
      sourceUrl: response.url,
    };

    return payload;
  } catch (error) {
    errorToast({ message: 'Error fetching heatmap image.', timeout: 2500 });
  }
};

export const isValidFilter = (filter) =>
  filter?.start &&
  filter?.end &&
  Array.isArray(filter?.heatmapType) &&
  filter.heatmapType.length > 0;

export const fetchHeatmaps = async (
  entities: Array<string>,
  entityType: string, // location or recording
  filter,
): Promise<Array<Object> | null> => {
  try {
    if (
      !isValidFilter(filter) &&
      (typeof entities === 'undefined' ||
        (Array.isArray(entities) && entities.length === 0))
    ) {
      return null;
    }
    const query = {
      entityType,
      returnEntityType: 'heatmap',
      entities,
      filter,
    };

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results;
  } catch (error) {
    console.log(error);
    errorToast({
      message: 'Error fetching heatmaps.',
      timeout: 2500,
    });

    return [];
  }
};
