import {
  ButtonGroup,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  max-height: 50vh;
  overflow-y: auto;
`;

export const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 2px;
`;

export const NoItems = styled.div`
  margin: 1rem 0;
  color: #999;
  width: 100%;
  text-align: center;
`;
const BASE_LEFT_PADDING = 0.5;
export const IconActiveFilter = styled(Icon)`
  &&& {
    margin-left: auto;
    max-height: 13px;
    color: rgba(0, 0, 0, 0.5);
  }
`;
export const ButtonGroupStyled = styled(ButtonGroup)`
  flex-shrink: 0;
  ${({ activeColor }) =>
    activeColor
      ? `border-radius: 50px; border-bottom: 3px solid; border-bottom-color: ${activeColor};`
      : ''}
`;

export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  padding-left: ${({ space }) =>
    space < 1 ? space : 1 * space + BASE_LEFT_PADDING}em;
  padding-right: ${BASE_LEFT_PADDING}em;
  &:hover {
    cursor: pointer;
    background-color: rgba(167, 182, 194, 0.3);
  }
`;

const baseIndentation = 21;
export const IndentedMenuItem = styled(MenuItem)`
  ${({ indentationLevel }) =>
    `padding-left: ${baseIndentation * (indentationLevel || 1)}px;
  cursor: pointer;`}
`;
