import { useState, useCallback } from 'react';

function useLoadingHandler() {
  const [loadingStates, setLoadingStates] = useState({});

  const startLoading = useCallback((key) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [key]: true,
    }));
  }, []);

  const stopLoading = useCallback((key) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [key]: false,
    }));
  }, []);

  const isLoading = Object.values(loadingStates).some((state) => state);

  return {
    startLoading,
    stopLoading,
    isLoading,
  };
}

export default useLoadingHandler;
