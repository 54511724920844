import { Fragment, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ButtonGroup,
  Icon,
  MenuDivider,
} from '@blueprintjs/core';
import { IndentedMenuItem, NoItems, StyledMenu } from './styled';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';

export const getHierarchicalTaxonomyButtonText = (
  maxLength,
  selectedItems,
  text,
) => {
  if (maxLength === selectedItems.length) {
    return text ? text : `All (${maxLength} of ${maxLength})`;
  } else {
    return `${selectedItems.length} of ${maxLength}`;
  }
};

export const AuraHierarchicalSubMenu = ({
  allItems, // [{ key:string, values: string[]}]
  allKeyStrings,
  selectedItems,
  updateSelectedItems,
  noItemsText,
  itemsTypeText,
  canBeEmpty,
}) => {
  const [filterSelection, setFilterSelection] = useState(
    new Set(selectedItems),
  );

  const handleUpdateSelectedItems = useCallback(() => {
    updateSelectedItems(
      filterSelection.size === 0 ? [] : Array.from(filterSelection),
    );
  }, [updateSelectedItems, filterSelection]);

  const handleFilterItemsChange = (keyString) => {
    const isSelectableNode = keyString.split(':').length > 1;
    const newSelection = new Set(filterSelection);

    if (isSelectableNode) {
      // Check if any children of this node are already selected
      const hasSelectedChildren = Array.from(filterSelection).some((str) =>
        str.includes(keyString),
      );

      if (hasSelectedChildren) {
        // If children exist, remove them all
        newSelection.forEach((str) => {
          if (str.includes(keyString)) {
            newSelection.delete(str);
          }
        });
      } else {
        // If no children, add matching items
        if (allKeyStrings && allKeyStrings.length > 0) {
          const matchingStrings = allKeyStrings.filter((str) =>
            str.includes(keyString),
          );
          matchingStrings.forEach((str) => newSelection.add(str));
        }
      }
    } else {
      // For leaf nodes, just toggle their state
      if (filterSelection.has(keyString)) {
        newSelection.delete(keyString);
      } else {
        newSelection.add(keyString);
      }
    }
    setFilterSelection(newSelection);
  };

  const generateMenuItems = (values, indentationLevel, parentPath = '') => {
    return values.map((val) => {
      if (typeof val === 'string') {
        const currentPath = parentPath ? `${parentPath}:${val}` : val;
        return (
          <IndentedMenuItem
            indentationLevel={indentationLevel}
            key={`item-${currentPath}`}
            text={firstLetterUppercase(val)}
            onClick={() => handleFilterItemsChange(currentPath)}
            label={filterSelection.has(currentPath) && <Icon icon="tick" />}
            shouldDismissPopover={false}
          />
        );
      } else if (
        typeof val === 'object' &&
        val.key &&
        Array.isArray(val.values) &&
        val.values.length > 0
      ) {
        const currentPath = parentPath ? `${parentPath}:${val.key}` : val.key;
        return (
          <Fragment key={`group-${currentPath}`}>
            <IndentedMenuItem
              indentationLevel={indentationLevel}
              key={`parent-${currentPath}`}
              text={firstLetterUppercase(val.key)}
              onClick={() => handleFilterItemsChange(currentPath)}
              shouldDismissPopover={false}
            />
            {generateMenuItems(val.values, indentationLevel + 1, currentPath)}
          </Fragment>
        );
      }
      return null; // Handle any invalid items
    });
  };

  const renderedItems = useMemo(() => {
    return allItems.map(
      ({ key, values }) =>
        typeof key === 'string' &&
        values &&
        values.length > 0 && (
          <Fragment key={`section-${key}`}>
            <MenuItem
              key={`header-${key}`}
              text={firstLetterUppercase(key)}
              style={{ pointerEvents: 'none' }}
            />
            {generateMenuItems(values, 1, key)}
            <MenuDivider />
          </Fragment>
        ),
    );
  }, [allItems, filterSelection]);

  return (
    <Menu>
      <StyledMenu>
        {allItems.length > 0 ? renderedItems : <NoItems>{noItemsText}</NoItems>}
      </StyledMenu>
      {allItems.length > 0 && (
        <Fragment>
          <ButtonGroup fill={true}>
            <Button
              icon="cross"
              disabled={filterSelection.size === 0}
              onClick={() => setFilterSelection(new Set())}
            >
              Clear selection
            </Button>
          </ButtonGroup>
          <Button
            intent="success"
            style={{ marginTop: '0.25rem' }}
            fill={true}
            disabled={
              typeof canBeEmpty !== 'undefined' && canBeEmpty
                ? false
                : filterSelection.size === 0
            }
            onClick={handleUpdateSelectedItems}
          >
            Filter {firstLetterUppercase(itemsTypeText)}
          </Button>
        </Fragment>
      )}
    </Menu>
  );
};
