import HeatmapFilterRow from './heatmap-filter-row';
import HeatmapDisplayGrid from './heatmap-display-grid';

import { periodPresetsT } from '../../models/period';
import * as RecordingModel from '../../models/recording';
import * as LocationModel from '../../models/location';
import { HeatmapWrapper } from './styled';
import { errorToast } from '../../utils/toaster';
import {
  ENTITY_TYPES,
  ERROR_MESSAGES,
  TOAST_TIMEOUTS,
  REDUCER_ACTIONS,
} from '../../constants/heatmaps';
import { fetchHeatmaps } from '../../actions/heatmap';
import { useEffect } from 'react';
import { uniqBy } from 'lodash';
import { fetchLineContextsForRecordings } from '../../actions/line-contexts';
import { fetchAreaContextsForRecordings } from '../../actions/area-contexts';
import useLoadingHandler from '../../hooks/use-loading-handler';
import {
  convertFromObjectToUrlHash,
  convertFromUrlHashToObject,
} from '../../utils/urlHelpers';
import {
  generateHeatmapGridObject,
  generateTaxonomyListFromContexts,
} from '../../utils/heatmapsHelpers';
import { useFilterOptionsContext } from './useFilterOptionsContext';
import moment from 'moment'; // Import moment library

export type KeyValuesArray = { key: String, values: String[] }[];

export type HeatmapFilterOptions = {
  location: { id: String, name: String } | undefined,
  recording: { id: String, name: String } | undefined,
  taxonomies: KeyValuesArray | undefined,
  selectedBreakdowns: KeyValuesArray,
  type: String[],
  startDate: String,
  endDate: String,
  selectedDate: String,
  currentSelectedDatePreset: periodPresetsT,
};

type Props = {
  recordings: RecordingModel.t[],
  locations: LocationModel.t[],
  showStaffFilters: boolean,
  disableAgeAndGenderOnFrontEnd: boolean,
};

const HeatmapPage = ({
  locations,
  recordings,
  showStaffFilters,
  disableAgeAndGenderOnFrontEnd,
}: Props) => {
  const { updateFilterOptions, state, ctxFetched } = useFilterOptionsContext();
  const { isLoading, startLoading, stopLoading } = useLoadingHandler();

  // Handle initial data fetching and hash conversion
  useEffect(() => {
    if (!ctxFetched.current) {
      getContexts();

      // After contexts are loaded, handle hash if present
      if (window.location.hash) {
        const hashedState = convertFromUrlHashToObject(window.location.hash);
        if (hashedState) {
          updateFilterOptions(REDUCER_ACTIONS.SET_FILTER_OPTIONS, hashedState);
        }
      }
    }
  }, []);

  // Handle enabled locations updates
  useEffect(() => {
    if (!isLoading) {
      handleEnabledLocations(locations, state.filterOptions);
    }
  }, [state.filterOptions, locations, isLoading]);

  useEffect(() => {
    // initial heatmap fetch
    if (state.ctx && !ctxFetched.current && !state.heatmaps.length) {
      fetchHeatmapData();
      ctxFetched.current = true;
    }
  }, [state.ctx]);

  const handleLoadHeatmaps = async () => {
    if (false) {
      // isRequestTooLarge(filterOptions)) {
      errorToast({
        message: ERROR_MESSAGES.REQUEST_TOO_LARGE_WITH_TAXONOMIES,
        timeout: TOAST_TIMEOUTS.LONG,
      });
    } else {
      // set the url hash to the current filter option configuration
      window.location.hash = convertFromObjectToUrlHash(state.filterOptions);
      updateFilterOptions(REDUCER_ACTIONS.SET_SHOULD_FETCH_HEATMAPS, false);
      // fetch the heatmaps
      await fetchHeatmapData();
    }
  };

  const fetchHeatmapData = async () => {
    updateFilterOptions(REDUCER_ACTIONS.SET_SHOULD_FETCH_HEATMAPS, false);
    const fetchKey = 'heatmap-data';
    try {
      startLoading(fetchKey);
      const { start, end, type, selectedBreakdowns } = state.filterOptions;

      // Create an array of daily date ranges between start and end
      const startDate = moment(start);
      const endDate = moment(end);
      const dayCount = endDate.diff(startDate, 'days') + 1;

      const dailyFilters = Array.from({ length: dayCount }, (_, index) => {
        const currentDate = moment(startDate).add(index, 'days');
        const dayStart = currentDate.startOf('day').toISOString();
        const dayEnd = currentDate.endOf('day').toISOString();

        const filter = {
          start: dayStart,
          end: dayEnd,
          heatmapType: [type.key],
        };

        if (selectedBreakdowns && selectedBreakdowns?.length > 0 && selectedBreakdowns[0]?.key !== '') {
          filter[selectedBreakdowns[0]?.key] =
            type.key === 'interaction' ?
              selectedBreakdowns[0]?.values
              :
              selectedBreakdowns[0]?.values?.map((v) =>
                v.replace('-', '_'),
              );
        }

        return filter;
      });

      const entityType =
        state.filterOptions?.taxonomies &&
          state.filterOptions?.taxonomies.length > 0
          ? ENTITY_TYPES.RECORDING
          : ENTITY_TYPES.LOCATION;

      const entities =
        entityType === ENTITY_TYPES.RECORDING
          ? state.filterOptions.recordings
          : state.filterOptions.locations;

      // Create and execute all promises in parallel
      const promises = dailyFilters.map((filter) =>
        fetchHeatmaps(entities, entityType, filter),
      );

      const responses = await Promise.allSettled(promises);

      const combinedResponse = responses.reduce((acc, response) => {
        if (response.status === 'fulfilled' && response.value !== null) {
          return acc.concat(response.value);
        }
        return acc;
      }, []);

      if (Array.isArray(combinedResponse)) {
        const newHeatmaps = generateHeatmapGridObject(
          state.filterOptions.selectedBreakdowns,
          combinedResponse,
          recordings,
          state.ctx,
          state.filterOptions.recordings,
        );

        updateFilterOptions(
          REDUCER_ACTIONS.SET_PREV_FETCHED_FILTER_OPTIONS,
          state.filterOptions,
        );
        updateFilterOptions(REDUCER_ACTIONS.SET_HEATMAPS, newHeatmaps);
      } else {
        throw new Error('Error fetching heatmaps promise all');
      }
    } catch (error) {
      console.error('Error fetching heatmaps:', error);
      errorToast({
        message: ERROR_MESSAGES.FETCH_HEATMAPS_FAILED,
        timeout: TOAST_TIMEOUTS.LONG,
      });
      updateFilterOptions(REDUCER_ACTIONS.SET_SHOULD_FETCH_HEATMAPS, true);
    } finally {
      stopLoading(fetchKey);
    }
  };

  const getContexts = async () => {
    const fetchKey = 'fetch-contexts';
    try {
      startLoading(fetchKey);
      const lineContexts = await fetchLineContextsForRecordings(
        recordings.map((rec) => rec.id),
      );
      const areaContexts = await fetchAreaContextsForRecordings(
        recordings.map((rec) => rec.id),
      );
      let contexts = [];
      if (lineContexts && areaContexts) {
        console.log('line contexts', lineContexts)
        const bothArrays = [...lineContexts, ...areaContexts];
        Array.from(bothArrays)
          .map(
            (ctx) =>
              ctx[1].length > 0 && ctx[1].filter((item) => item.taxonomy || item.line_type),
          )
          .filter((item) => item !== false)
          .forEach((item) => {
            item.forEach((subitem) => {
              if (!subitem.taxonomy && subitem.line_type) {
                subitem.taxonomy = 'Store:' + {
                  'location-entry': 'Entries',
                  'location-pass-by': 'Passers-by'
                }[subitem.line_type] || ''
              }
            })
            contexts.push(...item);
          });

        updateFilterOptions(REDUCER_ACTIONS.SET_CTX, contexts);
        updateFilterOptions(
          REDUCER_ACTIONS.SET_TAXONOMY_LIST,
          generateTaxonomyListFromContexts(contexts),
        );
        updateFilterOptions(
          REDUCER_ACTIONS.SET_TAXONOMY_KEY_STRINGS,
          uniqBy(contexts, 'taxonomy')
            .map((c) => c.taxonomy)
            .sort((a, b) => {
              if (a.startsWith('Store:') && !b.startsWith('Store:')) return -1;
              if (!a.startsWith('Store:') && b.startsWith('Store:')) return 1;
              return a.localeCompare(b, 'en', { numeric: true });
            }),
        );
        ctxFetched.current = true;
      }
    } catch (error) {
      console.error('Error loading contexts:', error);
      errorToast({
        message: ERROR_MESSAGES.FETCH_ERROR,
        timeout: TOAST_TIMEOUTS.SHORT,
      });
    } finally {
      stopLoading(fetchKey);
    }
  };

  const handleEnabledLocations = (locations, filterOptions) => {
    // when selecting a taxonomy we want to enable all locations that have that taxonomy
    if (filterOptions?.taxonomies && filterOptions?.taxonomies.length > 0) {
      const locationsWithTaxonomy = state.ctx
        .filter((c) => filterOptions.taxonomies.includes(c.taxonomy))
        .map((c) => c.location_id);
      updateFilterOptions(
        REDUCER_ACTIONS.SET_ENABLED_LOCATIONS,
        locations
          .filter((loc) => locationsWithTaxonomy.includes(loc.id))
          .map((loc) => loc.id),
      );
    } else if (locations.length !== state.enabledLocations.length) {
      updateFilterOptions(
        REDUCER_ACTIONS.SET_ENABLED_LOCATIONS,
        locations.map((loc) => loc.id),
      );
    }
  };

  return (
    <HeatmapWrapper>
      <HeatmapFilterRow
        locations={locations}
        enabledLocations={locations.map((l) => l.id)}
        recordings={recordings}
        taxonomies={state.filterOptions?.taxonomies || []}
        taxonomyKeyStrings={state?.taxonomyKeyStrings || []}
        showStaff={showStaffFilters}
        disableAgeAndGenderOnFrontEnd={disableAgeAndGenderOnFrontEnd}
        handleLoadHeatmaps={handleLoadHeatmaps}
        isLoading={isLoading}
      />
      <HeatmapDisplayGrid items={state?.heatmaps || []} isLoading={isLoading} />
    </HeatmapWrapper>
  );
};

export default HeatmapPage;
