import { GridControls, PageItemContainer, PaginationItem } from './styled';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { useMemo, useCallback, Fragment } from 'react';
import { ButtonGroupStyled } from '../filter-menu/styled';

type Props = {
  displayOptions: number[],
  currentPage: number,
  itemsPerPage: number,
  maxPage: number,
  updateNavigationState: (key: string, value: number) => void,
};

type SimpleSelectProps = {
  itemsPerPage: number,
  displayOptions: number[],
  defaultText: string,
  updateNavigationState: (key: string, value: number) => void,
};

const SimpleSelect = ({
  itemsPerPage,
  displayOptions,
  defaultText,
  updateNavigationState,
}: SimpleSelectProps) => {
  const handleItemsPerPageChange = useCallback((value) => {
    updateNavigationState('itemsPerPage', value);
  }, [updateNavigationState]);

  const items = useMemo(() => displayOptions, [displayOptions]);

  return (
    <Select
      activeItem={itemsPerPage}
      items={items}
      itemRenderer={(item, { handleClick }) => (
        <MenuItem key={item} text={item} onClick={handleClick} />
      )}
      onItemSelect={handleItemsPerPageChange}
      filterable={false}
    >
      <Button
        text={
          itemsPerPage
            ? `${itemsPerPage} per page`
            : defaultText
            ? defaultText
            : 'Select an Option'
        }
        rightIcon="double-caret-vertical"
      />
    </Select>
  );
};

const HeatmapGridControls = ({
  displayOptions,
  currentPage,
  itemsPerPage,
  maxPage,
  updateNavigationState,
}: Props) => {
  const handlePageChange = useCallback((value) => {
    updateNavigationState('currentPage', value);
  }, [updateNavigationState]);

  const getVisiblePages = useCallback((current, max) => {
    if (max <= 5) return Array.from({ length: max }, (_, i) => i + 1);

    const pages = new Set([1, max]); // Always include first and last
    
    // Add current page and 1 pages on each side, and 2-3 pages if near start or end
    for (let i = Math.max(1, current - Math.max(current - (max - 3), 1)); i <= Math.min(max - 1, current + Math.max(4-current, 1)); i++) {
      pages.add(i);
    }

    // Convert to array and sort
    return Array.from(pages).sort((a, b) => a - b);
  }, []);

  const pages = useMemo(() => getVisiblePages(currentPage, maxPage), [currentPage, maxPage, getVisiblePages]);

  return (
    <GridControls>
      <PageItemContainer>
        <ButtonGroupStyled>
          <Button text="Page:" style={{ pointerEvents: 'none' }} />
          {pages.map((page, index) => (
            <Button
              key={page}
              text={page}
              onClick={() => handlePageChange(page)}
              active={page === currentPage}
            />
          ))}
        </ButtonGroupStyled>
        <SimpleSelect
          defaultText="Items per page"
          itemsPerPage={itemsPerPage}
          displayOptions={displayOptions}
          updateNavigationState={updateNavigationState}
        />
      </PageItemContainer>
    </GridControls>
  );
};

export default HeatmapGridControls;
