// @flow

import { sendMessage } from '../services/sql-api';
import moment from 'moment';
import { errorToast } from '../utils/toaster';

export const fetchLocationUptimeStatus = async (
  locations?: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'location',
      entities: undefined,
    };

    if (locations && Array.isArray(locations) && locations.length > 0) {
      query.entities = locations;
    }

    return sendMessage('status', 'uptime', query);
  } catch (error) {
    errorToast({ message: "Error fetching uptime status'.", timeout: 2500 });
    console.log(error);
    return null;
  }
};

export const fetchRecordingUptimeStatus = async (
  recordings?: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'recording',
      entities: undefined,
    };

    if (recordings && Array.isArray(recordings) && recordings.length > 0) {
      query.entities = recordings;
    }

    return sendMessage('status', 'uptime', query);
  } catch (error) {
    console.log(error);
    errorToast({ message: 'Error fetching uptimes.', timeout: 2500 });
    return null;
  }
};

export const fetchLocationUptimes = async (
  locations?: Array<string>,
  start?: string,
  end?: string,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'location',
      entities: undefined,
      start: !!start
        ? start
        : moment
            .utc()
            .subtract({ days: 8 })
            .set({ hours: 0, seconds: 0, milliseconds: 0 })
            .toISOString(),
      end: !!end ? end : moment().toISOString(),
      aggregationPeriod: '15min',
      facets: ['segments'],
    };

    if (locations && Array.isArray(locations) && locations.length > 0) {
      query.entities = locations;
    }

    return sendMessage('metric', 'uptime', query);
  } catch (error) {
    console.log(error);
    errorToast({
      message: 'Error fetching location uptimes.',
      timeout: 2500,
    });
    return null;
  }
};

export const fetchRecordingUptimes = async (
  recordings?: Array<string>,
  start?: string,
  end?: string,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'recording',
      entities: undefined,
      start: !!start
        ? start
        : moment
            .utc()
            .subtract({ days: 8 })
            .set({ hours: 0, seconds: 0, milliseconds: 0 })
            .toISOString(),
      end: !!end ? end : moment().toISOString(),
      aggregationPeriod: '15min',
      facets: ['segments'],
    };

    if (recordings && Array.isArray(recordings) && recordings.length > 0) {
      query.entities = recordings;
    }

    return sendMessage('metric', 'uptime', query);
  } catch (error) {
    console.log(error);
    errorToast({ message: 'Error fetching uptimes.', timeout: 2500 });
    return null;
  }
};