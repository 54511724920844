// @flow

import { useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, NavbarGroup, NavbarHeading } from '@blueprintjs/core';
import Layout from '../../layouts/default-logged-in';
import { breakpoints } from '../../../styles/variables';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';

import * as RecordingModel from '../../../models/recording';
import * as LocationModel from '../../../models/location';

import HeatmapPage from '../../heatmap-page-components/index';
import { HEATMAPS_TEXT_CONTENT } from '../../../constants/heatmaps';
import { FilterOptionsProvider } from '../../heatmap-page-components/useFilterOptionsContext';

const TopBar = styled(Navbar)`
  padding-left: 2em;
  padding-right: 2em;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1em;
  }
`;

type Props = {
  recordings: RecordingModel.t[],
  locations: LocationModel.t[],
  showStaffFilters: boolean,
  disableAgeAndGenderOnFrontEnd: boolean,
};

const NoRecordings = () => (
  <TopBar>
    <NavbarGroup>
      <NavbarHeading>{HEATMAPS_TEXT_CONTENT.NO_CAMERAS}</NavbarHeading>
    </NavbarGroup>
  </TopBar>
);

const HeatmapsPage = (props: Props) => {
  useEffect(() => {
    trackEvent(ENTER_PAGE, 'HEATMAPS');
  }, []);

  const {
    recordings,
    locations,
    showStaffFilters,
    disableAgeAndGenderOnFrontEnd,
  } = props;
  return (
    <Layout>
      {recordings.length && locations.length ? (
        <FilterOptionsProvider
          locations={locations}
          recordings={recordings}
          showStaffFilters={showStaffFilters}
          disableAgeAndGenderOnFrontEnd={disableAgeAndGenderOnFrontEnd}
        >
          <HeatmapPage
            recordings={recordings}
            locations={locations}
          />
        </FilterOptionsProvider>
      ) : (
        <NoRecordings />
      )}
    </Layout>
  );
};

export default HeatmapsPage;
