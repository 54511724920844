import moment from 'moment';
import { getDaysBetweenDates } from '../../utils/dates';
import { periodPresetsT } from '../../models/period';
import {
  HeatmapSlider,
  MarkationCell,
  MarkationGrid,
  MarkationTick,
  SliderContainer,
} from './styled';
import { useMemo, useCallback } from 'react';

type Props = {
  start: String,
  end: String,
  selected: String,
  currentSelectedDatePreset: periodPresetsT,
  updateSelected: (selected: String) => void,
};

const generateDateArray = (start, end) => {
  const numDays = getDaysBetweenDates(start, end);
  return Array.from({ length: numDays + 1 }).map((_, index) =>
    moment(start).add(index, 'days').toISOString(),
  );
};

const HeatmapDateSlider = ({
  start,
  end,
  selected,
  maxWidth,
  updateSelected,
}: Props) => {
  const datesArray = useMemo(() => generateDateArray(start, end), [start, end]);

  const handleSliderChange = useCallback((value) => {
    updateSelected(datesArray[value]);
  }, [datesArray, updateSelected]);

  const labelRenderer = useCallback((value) => moment(datesArray[value]).format('dd Do MMM'), [datesArray]);

  return (
    <SliderContainer maxWidth={maxWidth}>
      {datesArray.length > 0 && (
        <MarkationGrid numColumns={datesArray.length}>
          {datesArray.map((date, i) => (
            <MarkationCell key={i}>
              <MarkationTick
                hidden={i === 0 || i === datesArray.length - 1}
                left={`${Math.round((i * 100) / (datesArray.length - 1))}%`}
              />
            </MarkationCell>
          ))}
        </MarkationGrid>
      )}
      <HeatmapSlider
        min={0}
        max={datesArray.length - 1}
        stepSize={1}
        labelStepSize={1}
        labelRenderer={labelRenderer}
        value={datesArray.indexOf(selected)}
        showTrackFill={false}
        onChange={handleSliderChange}
      />
    </SliderContainer>
  );
};

export default HeatmapDateSlider;
