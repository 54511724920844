/**
 * A unified filter menu component that supports three different types of filtering interfaces:
 * - default: Basic filter menu with single-level selection
 * - sub-menu: Filter menu with nested categories
 * - hierarchical: Filter menu with hierarchical data structure
 *
 * @component
 * @param {Object} props
 * @param {'default' | 'sub-menu' | 'hierarchical'} [props.type='default'] - The type of filter menu to render
 * @param {Array} props.allItems - Array of all available items for filtering
 * @param {Array} [props.allKeyStrings] - Array of key strings (used in hierarchical type)
 * @param {Array} props.selectedItems - Array of currently selected items
 * @param {Array} [props.disabledItems] - Array of items that should be disabled/unselectable
 * @param {Array} [props.enabledItems] - Array of items that should be enabled (used in default type)
 * @param {Function} props.updateSelectedItems - Callback function when selection changes
 * @param {string} props.noItemsText - Text to display when no items are available
 * @param {string} props.itemsTypeText - Text describing the type of items being filtered
 * @param {boolean} props.isLoading - Whether the items are currently loading
 * @param {boolean} props.canSelectAll - Whether all items can be selected at once
 * @param {boolean} [props.disabled] - Whether the filter menu is disabled
 * @param {string} [props.activeColour] - Color to use for active/selected state
 * @param {number} [props.maxSelectedItems] - Maximum number of items that can be selected
 * @param {boolean} [props.canBeEmpty] - Whether the selection can be empty
 * @param {boolean} [props.disableMultipleKeys] - Whether to disable multiple key selection (used in sub-menu type)
 * @returns {React.ReactElement} A filter menu component
 */
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Position, Popover } from '@blueprintjs/core';
import { ButtonGroupStyled } from './styled';
import { AuraMenu } from './aura-filter-menu';
import { AuraSubMenu } from './aura-filter-sub-menu';
import { AuraHierarchicalSubMenu } from './aura-filter-hierarchical-menu';
import { getFilterLocationsButtonText } from '../../utils/summaryPageHelpers';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';
import { getHierarchicalTaxonomyButtonText } from './aura-filter-hierarchical-menu';
import { getSubMenuTaxonomyButtonText } from './aura-filter-sub-menu';

export const AuraFilterMenu = ({
  type = 'default',
  allItems,
  allKeyStrings,
  selectedItems,
  disabledItems,
  enabledItems,
  updateSelectedItems,
  noItemsText,
  itemsTypeText,
  isLoading,
  canSelectAll,
  disabled,
  activeColour,
  maxSelectedItems,
  canBeEmpty,
  disableMultipleKeys,
  groupedBy,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!hasLoaded && !isLoading && allItems?.length > 0) {
      setHasLoaded(true);
    }
  }, [isLoading, allItems, hasLoaded]);

  const handleUpdateSelectedItems = useCallback(
    (val) => {
      setIsVisible(false);
      updateSelectedItems(val);
    },
    [updateSelectedItems],
  );

  const getButtonText = () => {
    if (disabled) return 'All';

    if (type === 'default') {
      return getFilterLocationsButtonText(
        allItems.length,
        selectedItems.length,
        `All ${firstLetterUppercase(itemsTypeText)}`,
      );
    }

    const itemCount =
      type === 'hierarchical'
        ? allKeyStrings?.length
        : allItems.map((i) => i.values).flat().length;

    if (
      type === 'hierarchical' &&
      (!allKeyStrings || allKeyStrings.length === 0)
    ) {
      return 'Unavailable';
    } else if (type === 'hierarchical') {
      return getHierarchicalTaxonomyButtonText(
        itemCount,
        selectedItems,
        `All ${firstLetterUppercase(itemsTypeText)}`,
      );
    }

    return getSubMenuTaxonomyButtonText(
      itemCount,
      selectedItems,
      `All ${firstLetterUppercase(itemsTypeText)}`,
    );
  };

  const getMenuComponent = () => {
    switch (type) {
      case 'hierarchical':
        return (
          <AuraHierarchicalSubMenu
            allItems={allItems}
            allKeyStrings={allKeyStrings}
            selectedItems={selectedItems}
            disabledItems={disabledItems}
            updateSelectedItems={handleUpdateSelectedItems}
            noItemsText={noItemsText}
            itemsTypeText={itemsTypeText}
            canSelectAll={canSelectAll}
            canBeEmpty={canBeEmpty}
          />
        );
      case 'sub-menu':
        return (
          <AuraSubMenu
            allItems={allItems}
            selectedItems={selectedItems}
            disabledItems={disabledItems}
            updateSelectedItems={handleUpdateSelectedItems}
            noItemsText={noItemsText}
            itemsTypeText={itemsTypeText}
            canSelectAll={canSelectAll}
            canBeEmpty={canBeEmpty}
            disableMultipleKeys={disableMultipleKeys}
          />
        );
      default:
        return (
          <AuraMenu
            allItems={allItems}
            selectedItems={selectedItems}
            enabledItems={enabledItems}
            updateSelectedItems={handleUpdateSelectedItems}
            noItemsText={noItemsText}
            itemsTypeText={itemsTypeText}
            canSelectAll={canSelectAll}
            maxSelectedItems={maxSelectedItems}
            groupedBy={groupedBy}
          />
        );
    }
  };

  const shouldShowPopover =
    type === 'default' ? allItems && allItems.length > 0 : allItems;

  return (
    <ButtonGroupStyled activeColour={activeColour}>
      <Button style={{ pointerEvents: 'none' }} icon="filter-list">
        {firstLetterUppercase(itemsTypeText)}
      </Button>
      {shouldShowPopover && (
        <Popover
          isOpen={isVisible}
          onClose={() => setIsVisible(false)}
          position={Position.BOTTOM}
        >
          <Button
            rightIcon="chevron-down"
            loading={
              type === 'hierarchical' ? isLoading && !hasLoaded : isLoading
            }
            disabled={disabled}
            onClick={() => setIsVisible(!isVisible)}
          >
            {getButtonText()}
          </Button>
          {getMenuComponent()}
        </Popover>
      )}
    </ButtonGroupStyled>
  );
};
