import {
  filterTypesArray,
  HEATMAP_KEY_COLOURS,
  UPDATE_FILTER_OPTIONS_ACTIONS,
} from '../../constants/heatmaps';
import { Panel } from '../panel';
import { AuraSelect } from '../search';
import {
  StyledPanelHeader,
  Container,
  Row,
  HeatmapFilterWrapper,
} from './styled';
import HeatmapDateSlider from './heatmap-date-slider';
import useHeatmapBreakdownOptions from '../../hooks/use-heatmap-breakdown-options';
import { Button, Intent } from '@blueprintjs/core';
import { ButtonGroupStyled } from '../filter-menu/styled';
import { KeyValuesArray } from './index';
import { useCallback, useMemo } from 'react';
import { useFilterOptionsContext } from './useFilterOptionsContext';
import SimpleDatePicker from '../date-and-comparison-date-selector/simple-date-picker';
import { AuraFilterMenu } from '../filter-menu';
import { findRecordings } from '../../utils/heatmapsHelpers';
import moment from 'moment';
type Props = {
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
  taxonomies: KeyValuesArray,
  taxonomyKeyStrings: String[],
  showStaff: Boolean,
  disableAgeAndGenderOnFrontEnd: Boolean,
  handleLoadHeatmaps: () => void,
  isLoading: Boolean,
};

const HeatmapFilterRow = ({
  locations,
  recordings,
  handleLoadHeatmaps,
  isLoading,
}: Props) => {
  const { state, updateFilterOptions, ctxFetched } = useFilterOptionsContext();
  const {
    filterOptions,
    enabledLocations,
    shouldFetchHeatmaps,
    cardSize,
    taxonomyList,
    taxonomyKeyStrings,
  } = state;

  const handleFilterUpdate = useCallback(
    (type, value) => {
      updateFilterOptions(type, value);
    },
    [updateFilterOptions],
  );

  const handleLoadHeatmapsClick = useCallback(() => {
    if (!isLoading) {
      handleLoadHeatmaps();
    }
  }, [isLoading, handleLoadHeatmaps]);

  const sortedLocations = useMemo(() => {
    return locations.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true }),
    );
  }, [locations]);

  const sortedRecordings = useMemo(() => {
    // Only show recordings for the currently selected locations
    const selectedLocationIds = filterOptions.locations;
    return recordings
      .filter((rec) => selectedLocationIds?.includes(rec.location.id))
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
  }, [recordings, filterOptions.locations]);

  // disable recording selection if it doesn't have selected taxonomies
  const enabledRecordings = useMemo(() => {
    if (state?.ctx && state?.filterOptions?.taxonomies?.length > 0) {
      const recordingsWithTaxonomy = state.ctx
        .filter(
          (c) =>
            state.filterOptions.taxonomies.includes(c.taxonomy) &&
            state.filterOptions.locations.includes(c.location_id),
        )
        .map(({ recording_id }) => recording_id);
      return recordings
        .filter((rec) => recordingsWithTaxonomy.includes(rec.id))
        .map((rec) => rec.id);
    } else {
      return findRecordings(filterOptions.locations, recordings).map((rec) => rec.id);
    }
  }, [
    recordings,
    state.filterOptions.taxonomies,
    state.ctx,
    state.filterOptions.locations,
  ]);

  // moved to useFilterOptionsContext
  // const breakdownOptions = useMemo(() => {
  //   return useHeatmapBreakdownOptions(showStaff, disableAgeAndGenderOnFrontEnd, filterOptions.type);
  // }, [showStaff, disableAgeAndGenderOnFrontEnd, filterOptions.type]);

  return (
    <HeatmapFilterWrapper>
      <Panel>
        <StyledPanelHeader>
          <Row>
            <Container>
              {/* Taxonomy - filter */}
              {!window.isDemoOrg && (
                <AuraFilterMenu
                  type={'hierarchical'}
                  allItems={taxonomyList}
                  allKeyStrings={taxonomyKeyStrings}
                  selectedItems={filterOptions?.taxonomies}
                  updateSelectedItems={(items) =>
                    handleFilterUpdate(
                      UPDATE_FILTER_OPTIONS_ACTIONS.TAXONOMIES,
                      items,
                    )
                  }
                  isLoading={!ctxFetched.current ? isLoading : false}
                  noItemsText={'No lines or areas found'}
                  itemsTypeText={'Lines and Areas'}
                  activeColour={HEATMAP_KEY_COLOURS.taxonomy}
                  canBeEmpty={true}
                />
              )}
              {/* Location - search */}
              <AuraFilterMenu
                type={'default'}
                allItems={sortedLocations}
                enabledItems={enabledLocations}
                selectedItems={filterOptions.locations}
                updateSelectedItems={(locs) =>
                  handleFilterUpdate(
                    UPDATE_FILTER_OPTIONS_ACTIONS.LOCATIONS,
                    locs,
                  )
                }
                isLoading={false}
                noItemsText={'No locations found'}
                itemsTypeText={'locations'}
                activeColour={HEATMAP_KEY_COLOURS.location}
                canSelectAll={true}
              />
              {/* Recording / Camera - search */}
              {false && <AuraFilterMenu
                type={'default'}
                allItems={sortedRecordings}
                selectedItems={filterOptions.recordings}
                updateSelectedItems={(rec) =>
                  handleFilterUpdate(
                    UPDATE_FILTER_OPTIONS_ACTIONS.RECORDINGS,
                    rec,
                  )
                }
                enabledItems={enabledRecordings}
                isLoading={false}
                noItemsText={'No cameras found'}
                itemsTypeText={'cameras'}
                canSelectAll={true}
                disabled={false}
                groupedBy={'location.id'}
                activeColour={HEATMAP_KEY_COLOURS.recording}
              />}
              {/* Select heatmap type */}
              <AuraSelect
                key={'type'}
                activeItem={filterOptions.type}
                items={filterTypesArray()}
                textSelector={'display'}
                keySelector={'key'}
                defaultText={'Select Heatmap Type'}
                handleChange={(value) =>
                  handleFilterUpdate(UPDATE_FILTER_OPTIONS_ACTIONS.TYPE, value)
                }
              />
              {/* Breakdown 'type' - multi-select - max 1 key, e.g. age, gender, role or all */}
              <AuraFilterMenu
                type={'sub-menu'}
                allItems={filterOptions.breakdowns}
                selectedItems={filterOptions.selectedBreakdowns}
                updateSelectedItems={(items) =>
                  handleFilterUpdate(
                    UPDATE_FILTER_OPTIONS_ACTIONS.BREAKDOWN,
                    items,
                  )
                }
                isLoading={false}
                noItemsText={'No breakdowns found'}
                itemsTypeText={'breakdowns'}
                disableMultipleKeys={true}
                canBeEmpty={false}
                />
            </Container>
          </Row>
          <Row padding={'20px 0 0 0'}>
            <SimpleDatePicker
              updateSelected={(value) =>
                handleFilterUpdate(
                  UPDATE_FILTER_OPTIONS_ACTIONS.SELECTED_PERIOD,
                  value,
                )
              }
              selectedPeriod={{
                start: filterOptions.start,
                end: filterOptions.end,
              }}
              {...(window.isDemoOrg && { minDate: new Date('2024-10-01') })}
              maxDate={window.isDemoOrg ? new Date('2025-02-28') : moment(new Date()).subtract(1, 'day').toDate()}
            />
            <HeatmapDateSlider
              start={filterOptions.start}
              end={filterOptions.end}
              selected={filterOptions.selectedDate}
              updateSelected={(value) =>
                handleFilterUpdate(
                  UPDATE_FILTER_OPTIONS_ACTIONS.SELECTED_DATE,
                  value,
                )
              }
              maxWidth={'70%'}
            />
          </Row>
          <Row>
            <ButtonGroupStyled>
              <Button text="Card Size:" style={{ pointerEvents: 'none' }} />
              <Button
                text="S"
                onClick={() =>
                  handleFilterUpdate(UPDATE_FILTER_OPTIONS_ACTIONS.CARD_SIZE, 1)
                }
                active={cardSize === 1}
              />
              <Button
                text="M"
                onClick={() =>
                  handleFilterUpdate(UPDATE_FILTER_OPTIONS_ACTIONS.CARD_SIZE, 2)
                }
                active={cardSize === 2}
              />
              <Button
                text="L"
                onClick={() =>
                  handleFilterUpdate(UPDATE_FILTER_OPTIONS_ACTIONS.CARD_SIZE, 4)
                }
                active={cardSize === 4}
              />
            </ButtonGroupStyled>
            <Container>
              <Button
                loading={isLoading}
                intent={shouldFetchHeatmaps ? Intent.PRIMARY : Intent.NONE}
                disabled={!shouldFetchHeatmaps}
                text="Load Heatmaps"
                onClick={handleLoadHeatmapsClick}
              />
              <Button
                text="Reset Filters"
                onClick={() =>
                  handleFilterUpdate(UPDATE_FILTER_OPTIONS_ACTIONS.RESET, null)
                }
                disabled={isLoading}
                intent="danger"
              />
            </Container>
          </Row>
        </StyledPanelHeader>
      </Panel>
    </HeatmapFilterWrapper>
  );
};

export default HeatmapFilterRow;
